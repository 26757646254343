export const Onion = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1717,"Actual Price(INR)/Quintal":1848,"Percentage Difference":"7.09%","YEAR":2024},
{ "Year : Month Week#":"Janwwk2","Predicted Price(INR)/Quintal":1851,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"-2.38%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1789,"Actual Price(INR)/Quintal":1547,"Percentage Difference":"-15.64%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1409,"Actual Price(INR)/Quintal":1235,"Percentage Difference":"-14.09%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1154,"Actual Price(INR)/Quintal":1257,"Percentage Difference":"8.19%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1207,"Actual Price(INR)/Quintal":1330,"Percentage Difference":"9.25%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1572,"Actual Price(INR)/Quintal":1635,"Percentage Difference":"3.85%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1740,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"3.33%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1739,"Actual Price(INR)/Quintal":1592,"Percentage Difference":"-9.23%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1397,"Actual Price(INR)/Quintal":1421,"Percentage Difference":"1.69%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1387,"Actual Price(INR)/Quintal":1464,"Percentage Difference":"5.26%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1453,"Actual Price(INR)/Quintal":1395,"Percentage Difference":"-4.16%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1456,"Actual Price(INR)/Quintal":1542,"Percentage Difference":"5.58%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1501,"Actual Price(INR)/Quintal":1450,"Percentage Difference":"-3.52%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1526,"Actual Price(INR)/Quintal":1497,"Percentage Difference":"-1.94%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1416,"Actual Price(INR)/Quintal":1424,"Percentage Difference":"0.56%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1490,"Actual Price(INR)/Quintal":1500,"Percentage Difference":"0.67%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1494,"Actual Price(INR)/Quintal":1559,"Percentage Difference":"4.17%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1491,"Actual Price(INR)/Quintal":1498,"Percentage Difference":"0.47%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1562,"Actual Price(INR)/Quintal":1626,"Percentage Difference":"3.94%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1788,"Actual Price(INR)/Quintal":1865,"Percentage Difference":"4.13%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2243,"Actual Price(INR)/Quintal":2485,"Percentage Difference":"9.74%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2262,"Actual Price(INR)/Quintal":2326,"Percentage Difference":"2.75%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2266,"Actual Price(INR)/Quintal":2327,"Percentage Difference":"2.62%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2274,"Actual Price(INR)/Quintal":2365,"Percentage Difference":"3.85%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2466,"Actual Price(INR)/Quintal":2610,"Percentage Difference":"5.52%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2599,"Actual Price(INR)/Quintal":2575,"Percentage Difference":"-0.93%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2558,"Actual Price(INR)/Quintal":2587,"Percentage Difference":"1.12%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2544,"Actual Price(INR)/Quintal":2591,"Percentage Difference":"1.81%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2457,"Actual Price(INR)/Quintal":2708,"Percentage Difference":"9.27%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2909,"Actual Price(INR)/Quintal":3095,"Percentage Difference":"6.01%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2920,"Actual Price(INR)/Quintal":3150,"Percentage Difference":"7.30%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":3139,"Actual Price(INR)/Quintal":3049,"Percentage Difference":"-2.95%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":3145,"Actual Price(INR)/Quintal":3117,"Percentage Difference":"-0.90%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":3137,"Actual Price(INR)/Quintal":3226,"Percentage Difference":"2.76%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":3258,"Actual Price(INR)/Quintal":3344,"Percentage Difference":"2.57%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":3297,"Actual Price(INR)/Quintal":2770,"Percentage Difference":"-19.03%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2716,"Actual Price(INR)/Quintal":2794,"Percentage Difference":"2.79%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2713,"Actual Price(INR)/Quintal":3238,"Percentage Difference":"16.21%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":3242,"Actual Price(INR)/Quintal":3094,"Percentage Difference":"-4.78%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":3068,"Actual Price(INR)/Quintal":3045,"Percentage Difference":"-0.76%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":3035,"Actual Price(INR)/Quintal":3025,"Percentage Difference":"-0.33%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":3001,"Actual Price(INR)/Quintal":3060,"Percentage Difference":"1.93%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":3237,"Actual Price(INR)/Quintal":3547,"Percentage Difference":"8.74%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":3826,"Actual Price(INR)/Quintal":4100,"Percentage Difference":"6.68%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":4177,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":4147,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":4122,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1850,"Actual Price(INR)/Quintal":1883,"Percentage Difference":"1.75%","YEAR":2023},
{ "Year : Month Week#":"Janwwk2","Predicted Price(INR)/Quintal":1904,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"-0.21%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1822,"Actual Price(INR)/Quintal":1814,"Percentage Difference":"-0.44%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1812,"Actual Price(INR)/Quintal":1829,"Percentage Difference":"0.93%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1394,"Actual Price(INR)/Quintal":1400,"Percentage Difference":"0.43%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1375,"Actual Price(INR)/Quintal":1380,"Percentage Difference":"0.36%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1202,"Actual Price(INR)/Quintal":1120,"Percentage Difference":"-7.32%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1063,"Actual Price(INR)/Quintal":1133,"Percentage Difference":"6.18%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1065,"Actual Price(INR)/Quintal":1075,"Percentage Difference":"0.93%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1063,"Actual Price(INR)/Quintal":1076,"Percentage Difference":"1.21%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1109,"Actual Price(INR)/Quintal":1185,"Percentage Difference":"6.41%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1166,"Actual Price(INR)/Quintal":1116,"Percentage Difference":"-4.48%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1144,"Actual Price(INR)/Quintal":1100,"Percentage Difference":"-4.00%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1106,"Actual Price(INR)/Quintal":1121,"Percentage Difference":"1.34%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1103,"Actual Price(INR)/Quintal":1093,"Percentage Difference":"-0.91%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1105,"Actual Price(INR)/Quintal":1150,"Percentage Difference":"3.91%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1116,"Actual Price(INR)/Quintal":1050,"Percentage Difference":"-6.29%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1107,"Actual Price(INR)/Quintal":1069,"Percentage Difference":"-3.55%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":866,"Actual Price(INR)/Quintal":903,"Percentage Difference":"4.10%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":853,"Actual Price(INR)/Quintal":864,"Percentage Difference":"1.27%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":911,"Actual Price(INR)/Quintal":1002,"Percentage Difference":"9.08%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1186,"Actual Price(INR)/Quintal":1232,"Percentage Difference":"3.73%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1216,"Actual Price(INR)/Quintal":1156,"Percentage Difference":"-5.19%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1236,"Actual Price(INR)/Quintal":1223,"Percentage Difference":"-1.06%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1326,"Actual Price(INR)/Quintal":1448,"Percentage Difference":"8.43%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1434,"Actual Price(INR)/Quintal":1480,"Percentage Difference":"3.11%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1464,"Actual Price(INR)/Quintal":1390,"Percentage Difference":"-5.32%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1422,"Actual Price(INR)/Quintal":1473,"Percentage Difference":"3.46%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1465,"Actual Price(INR)/Quintal":1527,"Percentage Difference":"4.06%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1476,"Actual Price(INR)/Quintal":1633,"Percentage Difference":"9.61%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1694,"Actual Price(INR)/Quintal":1845,"Percentage Difference":"8.18%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1998,"Actual Price(INR)/Quintal":1999,"Percentage Difference":"0.05%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1920,"Actual Price(INR)/Quintal":1930,"Percentage Difference":"0.52%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1964,"Actual Price(INR)/Quintal":2084,"Percentage Difference":"5.76%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1986,"Actual Price(INR)/Quintal":1996,"Percentage Difference":"0.50%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1999,"Actual Price(INR)/Quintal":1898,"Percentage Difference":"-5.32%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1903,"Actual Price(INR)/Quintal":1977,"Percentage Difference":"3.74%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2092,"Actual Price(INR)/Quintal":2063,"Percentage Difference":"-1.41%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2082,"Actual Price(INR)/Quintal":2312,"Percentage Difference":"9.95%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":3200,"Actual Price(INR)/Quintal":4000,"Percentage Difference":"20.00%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":4231,"Actual Price(INR)/Quintal":3509,"Percentage Difference":"-20.58%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":3420,"Actual Price(INR)/Quintal":3622,"Percentage Difference":"5.58%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":3571,"Actual Price(INR)/Quintal":3426,"Percentage Difference":"-4.23%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":3199,"Actual Price(INR)/Quintal":3126,"Percentage Difference":"-2.34%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":3037,"Actual Price(INR)/Quintal":3287,"Percentage Difference":"7.61%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2808,"Actual Price(INR)/Quintal":2687,"Percentage Difference":"-4.50%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2392,"Actual Price(INR)/Quintal":2290,"Percentage Difference":"-4.45%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2010,"Actual Price(INR)/Quintal":1769,"Percentage Difference":"-13.62%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2353,"Actual Price(INR)/Quintal":2435,"Percentage Difference":"3.37%","YEAR":2022},
{ "Year : Month Week#":"Janwwk2","Predicted Price(INR)/Quintal":2302,"Actual Price(INR)/Quintal":2131,"Percentage Difference":"-8.02%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1804,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"12.00%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1911,"Actual Price(INR)/Quintal":1813,"Percentage Difference":"-5.41%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1688,"Actual Price(INR)/Quintal":1756,"Percentage Difference":"3.87%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2109,"Actual Price(INR)/Quintal":2170,"Percentage Difference":"2.81%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2166,"Actual Price(INR)/Quintal":2182,"Percentage Difference":"0.73%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1980,"Actual Price(INR)/Quintal":1922,"Percentage Difference":"-3.02%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1499,"Actual Price(INR)/Quintal":1512,"Percentage Difference":"0.86%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1395,"Actual Price(INR)/Quintal":1377,"Percentage Difference":"-1.31%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1303,"Actual Price(INR)/Quintal":1267,"Percentage Difference":"-2.84%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1208,"Actual Price(INR)/Quintal":1186,"Percentage Difference":"-1.85%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1202,"Actual Price(INR)/Quintal":1186,"Percentage Difference":"-1.35%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1205,"Actual Price(INR)/Quintal":1100,"Percentage Difference":"-9.55%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1023,"Actual Price(INR)/Quintal":1033,"Percentage Difference":"0.97%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1099,"Actual Price(INR)/Quintal":1116,"Percentage Difference":"1.52%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1203,"Actual Price(INR)/Quintal":1212,"Percentage Difference":"0.74%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1148,"Actual Price(INR)/Quintal":1187,"Percentage Difference":"3.29%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1201,"Actual Price(INR)/Quintal":1147,"Percentage Difference":"-4.71%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1055,"Actual Price(INR)/Quintal":1066,"Percentage Difference":"1.03%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1101,"Actual Price(INR)/Quintal":1161,"Percentage Difference":"5.17%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1133,"Actual Price(INR)/Quintal":1176,"Percentage Difference":"3.66%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1223,"Actual Price(INR)/Quintal":1270,"Percentage Difference":"3.70%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1249,"Actual Price(INR)/Quintal":1280,"Percentage Difference":"2.42%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1419,"Actual Price(INR)/Quintal":1500,"Percentage Difference":"5.40%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1589,"Actual Price(INR)/Quintal":1599,"Percentage Difference":"0.63%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1565,"Actual Price(INR)/Quintal":1633,"Percentage Difference":"4.16%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1575,"Actual Price(INR)/Quintal":1544,"Percentage Difference":"-2.01%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1488,"Actual Price(INR)/Quintal":1472,"Percentage Difference":"-1.09%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1450,"Actual Price(INR)/Quintal":1469,"Percentage Difference":"1.29%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1457,"Actual Price(INR)/Quintal":1391,"Percentage Difference":"-4.74%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1454,"Actual Price(INR)/Quintal":1400,"Percentage Difference":"-3.86%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1325,"Actual Price(INR)/Quintal":1383,"Percentage Difference":"4.19%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1355,"Actual Price(INR)/Quintal":1420,"Percentage Difference":"4.58%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1357,"Actual Price(INR)/Quintal":1371,"Percentage Difference":"1.02%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1339,"Actual Price(INR)/Quintal":1330,"Percentage Difference":"-0.68%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1382,"Actual Price(INR)/Quintal":1500,"Percentage Difference":"7.87%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1407,"Actual Price(INR)/Quintal":1516,"Percentage Difference":"7.19%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1531,"Actual Price(INR)/Quintal":1600,"Percentage Difference":"4.31%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1612,"Actual Price(INR)/Quintal":1606,"Percentage Difference":"-0.37%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1621,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"9.94%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2633,"Actual Price(INR)/Quintal":2500,"Percentage Difference":"-5.32%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2302,"Actual Price(INR)/Quintal":2240,"Percentage Difference":"-2.77%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2011,"Actual Price(INR)/Quintal":2100,"Percentage Difference":"4.24%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2013,"Actual Price(INR)/Quintal":1961,"Percentage Difference":"-2.65%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1837,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"3.32%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1833,"Actual Price(INR)/Quintal":1857,"Percentage Difference":"1.29%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1865,"Actual Price(INR)/Quintal":1816,"Percentage Difference":"-2.70%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":2202,"Actual Price(INR)/Quintal":2230,"Percentage Difference":"1.26%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":2334,"Actual Price(INR)/Quintal":2396,"Percentage Difference":"2.59%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":2466,"Actual Price(INR)/Quintal":2543,"Percentage Difference":"3.03%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":2386,"Actual Price(INR)/Quintal":2316,"Percentage Difference":"-3.02%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":2899,"Actual Price(INR)/Quintal":2935,"Percentage Difference":"1.23%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":3014,"Actual Price(INR)/Quintal":3107,"Percentage Difference":"2.99%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":3171,"Actual Price(INR)/Quintal":3250,"Percentage Difference":"2.43%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":3226,"Actual Price(INR)/Quintal":3225,"Percentage Difference":"-0.03%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":2109,"Actual Price(INR)/Quintal":2097,"Percentage Difference":"-0.57%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1452,"Actual Price(INR)/Quintal":1350,"Percentage Difference":"-7.56%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1291,"Actual Price(INR)/Quintal":1241,"Percentage Difference":"-4.03%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1422,"Actual Price(INR)/Quintal":1337,"Percentage Difference":"-6.36%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1368,"Actual Price(INR)/Quintal":1275,"Percentage Difference":"-7.29%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1072,"Actual Price(INR)/Quintal":950,"Percentage Difference":"-12.84%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":850,"Actual Price(INR)/Quintal":897,"Percentage Difference":"5.24%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":851,"Actual Price(INR)/Quintal":908,"Percentage Difference":"6.28%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1081,"Actual Price(INR)/Quintal":1011,"Percentage Difference":"-6.92%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1133,"Actual Price(INR)/Quintal":1139,"Percentage Difference":"0.53%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1135,"Actual Price(INR)/Quintal":1118,"Percentage Difference":"-1.52%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1144,"Actual Price(INR)/Quintal":1143,"Percentage Difference":"-0.09%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1348,"Actual Price(INR)/Quintal":1410,"Percentage Difference":"4.40%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1544,"Actual Price(INR)/Quintal":1566,"Percentage Difference":"1.40%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1589,"Actual Price(INR)/Quintal":1637,"Percentage Difference":"2.93%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1699,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"5.61%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1886,"Actual Price(INR)/Quintal":1894,"Percentage Difference":"0.42%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1856,"Actual Price(INR)/Quintal":1895,"Percentage Difference":"2.06%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1859,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"-0.49%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1863,"Actual Price(INR)/Quintal":1920,"Percentage Difference":"2.97%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1968,"Actual Price(INR)/Quintal":1908,"Percentage Difference":"-3.14%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1827,"Actual Price(INR)/Quintal":1770,"Percentage Difference":"-3.22%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1995,"Actual Price(INR)/Quintal":1933,"Percentage Difference":"-3.21%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1986,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"-4.53%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1782,"Actual Price(INR)/Quintal":1650,"Percentage Difference":"-8.00%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1594,"Actual Price(INR)/Quintal":1500,"Percentage Difference":"-6.27%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1254,"Actual Price(INR)/Quintal":1233,"Percentage Difference":"-1.70%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1160,"Actual Price(INR)/Quintal":1220,"Percentage Difference":"4.92%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1846,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"6.53%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2353,"Actual Price(INR)/Quintal":2447,"Percentage Difference":"3.84%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2338,"Actual Price(INR)/Quintal":2480,"Percentage Difference":"5.73%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2344,"Actual Price(INR)/Quintal":2369,"Percentage Difference":"1.06%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2189,"Actual Price(INR)/Quintal":2167,"Percentage Difference":"-1.02%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2219,"Actual Price(INR)/Quintal":2343,"Percentage Difference":"5.29%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2378,"Actual Price(INR)/Quintal":2270,"Percentage Difference":"-4.76%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2287,"Actual Price(INR)/Quintal":2226,"Percentage Difference":"-2.74%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2041,"Actual Price(INR)/Quintal":1859,"Percentage Difference":"-9.79%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1865,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"1.84%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2142,"Actual Price(INR)/Quintal":2273,"Percentage Difference":"5.76%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2325,"Actual Price(INR)/Quintal":2256,"Percentage Difference":"-3.06%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":3373,"Actual Price(INR)/Quintal":3458,"Percentage Difference":"2.46%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":3194,"Actual Price(INR)/Quintal":3356,"Percentage Difference":"4.83%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":3023,"Actual Price(INR)/Quintal":3180,"Percentage Difference":"4.94%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":3315,"Actual Price(INR)/Quintal":3175,"Percentage Difference":"-4.41%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":2712,"Actual Price(INR)/Quintal":2449,"Percentage Difference":"-10.74%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":2086,"Actual Price(INR)/Quintal":1912,"Percentage Difference":"-9.10%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":2042,"Actual Price(INR)/Quintal":1937,"Percentage Difference":"-5.42%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1953,"Actual Price(INR)/Quintal":1890,"Percentage Difference":"-7.75%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1723,"Actual Price(INR)/Quintal":1650,"Percentage Difference":"-4.42%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1540,"Actual Price(INR)/Quintal":1639,"Percentage Difference":"6.04%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1753,"Actual Price(INR)/Quintal":1492,"Percentage Difference":"-17.49%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1348,"Actual Price(INR)/Quintal":1504,"Percentage Difference":"10.37%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1751,"Actual Price(INR)/Quintal":1600,"Percentage Difference":"-9.44%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1546,"Actual Price(INR)/Quintal":1346,"Percentage Difference":"-14.86%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1434,"Actual Price(INR)/Quintal":1193,"Percentage Difference":"-20.20%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":964,"Actual Price(INR)/Quintal":981,"Percentage Difference":"1.73%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":990,"Actual Price(INR)/Quintal":877,"Percentage Difference":"-12.88%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":826,"Actual Price(INR)/Quintal":780,"Percentage Difference":"-5.90%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":716,"Actual Price(INR)/Quintal":725,"Percentage Difference":"1.24%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":704,"Actual Price(INR)/Quintal":755,"Percentage Difference":"6.75%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":802,"Actual Price(INR)/Quintal":853,"Percentage Difference":"5.98%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":946,"Actual Price(INR)/Quintal":950,"Percentage Difference":"0.42%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":963,"Actual Price(INR)/Quintal":970,"Percentage Difference":"0.72%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1026,"Actual Price(INR)/Quintal":900,"Percentage Difference":"-14.00%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":802,"Actual Price(INR)/Quintal":850,"Percentage Difference":"5.65%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":873,"Actual Price(INR)/Quintal":861,"Percentage Difference":"-1.39%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":838,"Actual Price(INR)/Quintal":923,"Percentage Difference":"9.21%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":983,"Actual Price(INR)/Quintal":987,"Percentage Difference":"0.41%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":974,"Actual Price(INR)/Quintal":999,"Percentage Difference":"2.50%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":998,"Actual Price(INR)/Quintal":970,"Percentage Difference":"-2.89%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":977,"Actual Price(INR)/Quintal":1009,"Percentage Difference":"3.17%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":1251,"Actual Price(INR)/Quintal":1234,"Percentage Difference":"-1.38%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1256,"Actual Price(INR)/Quintal":1262,"Percentage Difference":"0.48%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1272,"Actual Price(INR)/Quintal":1316,"Percentage Difference":"3.34%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1490,"Actual Price(INR)/Quintal":1550,"Percentage Difference":"3.87%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":2033,"Actual Price(INR)/Quintal":2002,"Percentage Difference":"-1.55%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":2066,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"-3.66%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":2085,"Actual Price(INR)/Quintal":2173,"Percentage Difference":"4.05%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":4336,"Actual Price(INR)/Quintal":4699,"Percentage Difference":"7.73%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":5086,"Actual Price(INR)/Quintal":5250,"Percentage Difference":"3.12%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":5050,"Actual Price(INR)/Quintal":4682,"Percentage Difference":"-7.86%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":3339,"Actual Price(INR)/Quintal":3502,"Percentage Difference":"4.65%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":3470,"Actual Price(INR)/Quintal":3639,"Percentage Difference":"4.64%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":3481,"Actual Price(INR)/Quintal":3535,"Percentage Difference":"1.53%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":2666,"Actual Price(INR)/Quintal":2540,"Percentage Difference":"-4.96%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":2814,"Actual Price(INR)/Quintal":2836,"Percentage Difference":"0.78%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":2603,"Actual Price(INR)/Quintal":2499,"Percentage Difference":"-4.16%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":2314,"Actual Price(INR)/Quintal":2323,"Percentage Difference":"0.39%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 757,
    "Actual Price(INR)/Quintal": 807,
    "Percentage Difference": "6.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 868,
    "Actual Price(INR)/Quintal": 903,
    "Percentage Difference": "3.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 878,
    "Actual Price(INR)/Quintal": 812,
    "Percentage Difference": "-8.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 798,
    "Actual Price(INR)/Quintal": 788,
    "Percentage Difference": "-1.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 795,
    "Actual Price(INR)/Quintal": 797,
    "Percentage Difference": "0.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 811,
    "Actual Price(INR)/Quintal": 821,
    "Percentage Difference": "1.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 815,
    "Actual Price(INR)/Quintal": 823,
    "Percentage Difference": "0.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 823,
    "Actual Price(INR)/Quintal": 777,
    "Percentage Difference": "-5.92%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 786,
    "Actual Price(INR)/Quintal": 785,
    "Percentage Difference": "-0.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 767,
    "Actual Price(INR)/Quintal": 786,
    "Percentage Difference": "2.42%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 777,
    "Actual Price(INR)/Quintal": 800,
    "Percentage Difference": "2.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 808,
    "Actual Price(INR)/Quintal": 809,
    "Percentage Difference": "0.12%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 834,
    "Actual Price(INR)/Quintal": 843,
    "Percentage Difference": "1.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 818,
    "Actual Price(INR)/Quintal": 822,
    "Percentage Difference": "0.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 826,
    "Actual Price(INR)/Quintal": 833,
    "Percentage Difference": "0.84%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 809,
    "Actual Price(INR)/Quintal": 855,
    "Percentage Difference": "5.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 982,
    "Actual Price(INR)/Quintal": 988,
    "Percentage Difference": "0.61%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 988,
    "Actual Price(INR)/Quintal": 958,
    "Percentage Difference": "-3.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 960,
    "Actual Price(INR)/Quintal": 966,
    "Percentage Difference": "0.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 969,
    "Actual Price(INR)/Quintal": 1020,
    "Percentage Difference": "5.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1067,
    "Actual Price(INR)/Quintal": 1113,
    "Percentage Difference": "4.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1187,
    "Actual Price(INR)/Quintal": 1228,
    "Percentage Difference": "3.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1250,
    "Actual Price(INR)/Quintal": 1278,
    "Percentage Difference": "2.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1267,
    "Actual Price(INR)/Quintal": 1305,
    "Percentage Difference": "2.91%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1294,
    "Actual Price(INR)/Quintal": 1361,
    "Percentage Difference": "4.92%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1390,
    "Actual Price(INR)/Quintal": 1362,
    "Percentage Difference": "-2.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1391,
    "Actual Price(INR)/Quintal": 1342,
    "Percentage Difference": "-3.65%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1393,
    "Actual Price(INR)/Quintal": 1325,
    "Percentage Difference": "-5.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1291,
    "Actual Price(INR)/Quintal": 1333,
    "Percentage Difference": "3.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1418,
    "Actual Price(INR)/Quintal": 1466,
    "Percentage Difference": "3.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1438,
    "Actual Price(INR)/Quintal": 1596,
    "Percentage Difference": "9.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1845,
    "Actual Price(INR)/Quintal": 2005,
    "Percentage Difference": "7.98%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2298,
    "Actual Price(INR)/Quintal": 2310,
    "Percentage Difference": "0.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2511,
    "Actual Price(INR)/Quintal": 2550,
    "Percentage Difference": "1.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2572,
    "Actual Price(INR)/Quintal": 2808,
    "Percentage Difference": "8.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2894,
    "Actual Price(INR)/Quintal": 2900,
    "Percentage Difference": "0.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2906,
    "Actual Price(INR)/Quintal": 2899,
    "Percentage Difference": "-0.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2796,
    "Actual Price(INR)/Quintal": 2750,
    "Percentage Difference": "-1.67%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2816,
    "Actual Price(INR)/Quintal": 2670,
    "Percentage Difference": "-5.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 2728,
    "Actual Price(INR)/Quintal": 3040,
    "Percentage Difference": "10.26%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 3224,
    "Actual Price(INR)/Quintal": 4000,
    "Percentage Difference": "19.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 3715,
    "Actual Price(INR)/Quintal": 4000,
    "Percentage Difference": "7.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 3704,
    "Actual Price(INR)/Quintal": 4000,
    "Percentage Difference": "7.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 5590,
    "Actual Price(INR)/Quintal": 5826,
    "Percentage Difference": "4.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 6886,
    "Actual Price(INR)/Quintal": 7007,
    "Percentage Difference": "1.73%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 6884,
    "Actual Price(INR)/Quintal": 7007,
    "Percentage Difference": "1.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 8291,
    "Actual Price(INR)/Quintal": 8063,
    "Percentage Difference": "-2.83%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 5850,
    "Actual Price(INR)/Quintal": 5941,
    "Percentage Difference": "1.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2538,
    "Actual Price(INR)/Quintal": 2917,
    "Percentage Difference": "12.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2978,
    "Actual Price(INR)/Quintal": 2931,
    "Percentage Difference": "-1.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 2963,
    "Actual Price(INR)/Quintal": 2876,
    "Percentage Difference": "-3.03%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 2814,
    "Actual Price(INR)/Quintal": 2682,
    "Percentage Difference": "-4.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 2405,
    "Actual Price(INR)/Quintal": 2498,
    "Percentage Difference": "3.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 2207,
    "Actual Price(INR)/Quintal": 2006,
    "Percentage Difference": "-10.02%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1807,
    "Actual Price(INR)/Quintal": 1815,
    "Percentage Difference": "0.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1698,
    "Actual Price(INR)/Quintal": 1739,
    "Percentage Difference": "2.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1563,
    "Actual Price(INR)/Quintal": 1590,
    "Percentage Difference": "1.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1427,
    "Actual Price(INR)/Quintal": 1357,
    "Percentage Difference": "-5.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1137,
    "Actual Price(INR)/Quintal": 1115,
    "Percentage Difference": "-1.97%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1082,
    "Actual Price(INR)/Quintal": 1003,
    "Percentage Difference": "-7.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 852,
    "Actual Price(INR)/Quintal": 853,
    "Percentage Difference": "0.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 797,
    "Actual Price(INR)/Quintal": 805,
    "Percentage Difference": "0.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 743,
    "Actual Price(INR)/Quintal": 747,
    "Percentage Difference": "0.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 737,
    "Actual Price(INR)/Quintal": 772,
    "Percentage Difference": "4.53%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 740,
    "Actual Price(INR)/Quintal": 781,
    "Percentage Difference": "5.25%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 791,
    "Actual Price(INR)/Quintal": 828,
    "Percentage Difference": "4.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 794,
    "Actual Price(INR)/Quintal": 820,
    "Percentage Difference": "3.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 797,
    "Actual Price(INR)/Quintal": 801,
    "Percentage Difference": "0.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 808,
    "Actual Price(INR)/Quintal": 851,
    "Percentage Difference": "5.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 856,
    "Actual Price(INR)/Quintal": 904,
    "Percentage Difference": "5.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 909,
    "Actual Price(INR)/Quintal": 953,
    "Percentage Difference": "4.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 990,
    "Actual Price(INR)/Quintal": 996,
    "Percentage Difference": "0.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 985,
    "Actual Price(INR)/Quintal": 1092,
    "Percentage Difference": "9.80%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1287,
    "Actual Price(INR)/Quintal": 1206,
    "Percentage Difference": "-6.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1095,
    "Actual Price(INR)/Quintal": 1166,
    "Percentage Difference": "6.09%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1199,
    "Actual Price(INR)/Quintal": 1169,
    "Percentage Difference": "-2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1176,
    "Actual Price(INR)/Quintal": 1145,
    "Percentage Difference": "-2.71%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1131,
    "Actual Price(INR)/Quintal": 1129,
    "Percentage Difference": "-0.18%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1113,
    "Actual Price(INR)/Quintal": 1207,
    "Percentage Difference": "7.79%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1231,
    "Actual Price(INR)/Quintal": 1121,
    "Percentage Difference": "-9.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1063,
    "Actual Price(INR)/Quintal": 1029,
    "Percentage Difference": "-3.30%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1078,
    "Actual Price(INR)/Quintal": 1059,
    "Percentage Difference": "-1.79%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1073,
    "Actual Price(INR)/Quintal": 1094,
    "Percentage Difference": "1.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1011,
    "Actual Price(INR)/Quintal": 988,
    "Percentage Difference": "-2.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 976,
    "Actual Price(INR)/Quintal": 933,
    "Percentage Difference": "-4.61%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 922,
    "Actual Price(INR)/Quintal": 939,
    "Percentage Difference": "1.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 916,
    "Actual Price(INR)/Quintal": 1013,
    "Percentage Difference": "9.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1111,
    "Actual Price(INR)/Quintal": 1169,
    "Percentage Difference": "4.96%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1203,
    "Actual Price(INR)/Quintal": 1230,
    "Percentage Difference": "2.20%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1239,
    "Actual Price(INR)/Quintal": 1245,
    "Percentage Difference": "0.48%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1222,
    "Actual Price(INR)/Quintal": 1144,
    "Percentage Difference": "-6.82%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 920,
    "Actual Price(INR)/Quintal": 878,
    "Percentage Difference": "-4.78%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 784,
    "Actual Price(INR)/Quintal": 790,
    "Percentage Difference": "0.76%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 799,
    "Actual Price(INR)/Quintal": 819,
    "Percentage Difference": "2.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 729,
    "Actual Price(INR)/Quintal": 736,
    "Percentage Difference": "0.95%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 777,
    "Actual Price(INR)/Quintal": 737,
    "Percentage Difference": "-5.43%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1040,
    "Actual Price(INR)/Quintal": 975,
    "Percentage Difference": "-6.67%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 967,
    "Actual Price(INR)/Quintal": 900,
    "Percentage Difference": "-7.44%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 908,
    "Actual Price(INR)/Quintal": 924,
    "Percentage Difference": "1.73%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 918,
    "Actual Price(INR)/Quintal": 924,
    "Percentage Difference": "0.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 909,
    "Actual Price(INR)/Quintal": 870,
    "Percentage Difference": "-4.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 902,
    "Actual Price(INR)/Quintal": 895,
    "Percentage Difference": "-0.78%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 905,
    "Actual Price(INR)/Quintal": 895,
    "Percentage Difference": "-1.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 909,
    "Actual Price(INR)/Quintal": 888,
    "Percentage Difference": "-2.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 891,
    "Actual Price(INR)/Quintal": 899,
    "Percentage Difference": "0.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 897,
    "Actual Price(INR)/Quintal": 852,
    "Percentage Difference": "-5.82%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 859,
    "Actual Price(INR)/Quintal": 917,
    "Percentage Difference": "6.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 854,
    "Actual Price(INR)/Quintal": 914,
    "Percentage Difference": "6.56%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 906,
    "Actual Price(INR)/Quintal": 852,
    "Percentage Difference": "-6.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 865,
    "Actual Price(INR)/Quintal": 860,
    "Percentage Difference": "-0.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 878,
    "Actual Price(INR)/Quintal": 799,
    "Percentage Difference": "-9.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 796,
    "Actual Price(INR)/Quintal": 750,
    "Percentage Difference": "-6.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 754,
    "Actual Price(INR)/Quintal": 690,
    "Percentage Difference": "-9.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 704,
    "Actual Price(INR)/Quintal": 705,
    "Percentage Difference": "0.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 706,
    "Actual Price(INR)/Quintal": 735,
    "Percentage Difference": "3.95%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 735,
    "Actual Price(INR)/Quintal": 785,
    "Percentage Difference": "6.37%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 798,
    "Actual Price(INR)/Quintal": 840,
    "Percentage Difference": "5.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 825,
    "Actual Price(INR)/Quintal": 859,
    "Percentage Difference": "3.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 889,
    "Actual Price(INR)/Quintal": 859,
    "Percentage Difference": "-3.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 862,
    "Actual Price(INR)/Quintal": 909,
    "Percentage Difference": "5.17%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 909,
    "Actual Price(INR)/Quintal": 915,
    "Percentage Difference": "0.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 908,
    "Actual Price(INR)/Quintal": 849,
    "Percentage Difference": "-6.95%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 838,
    "Actual Price(INR)/Quintal": 832,
    "Percentage Difference": "-0.72%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 822,
    "Actual Price(INR)/Quintal": 859,
    "Percentage Difference": "4.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 849,
    "Actual Price(INR)/Quintal": 975,
    "Percentage Difference": "12.92%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1276,
    "Actual Price(INR)/Quintal": 1785,
    "Percentage Difference": "28.52%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1986,
    "Actual Price(INR)/Quintal": 1988,
    "Percentage Difference": "0.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1978,
    "Actual Price(INR)/Quintal": 1901,
    "Percentage Difference": "-4.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1914,
    "Actual Price(INR)/Quintal": 1792,
    "Percentage Difference": "-6.81%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1811,
    "Actual Price(INR)/Quintal": 1765,
    "Percentage Difference": "-2.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1536,
    "Actual Price(INR)/Quintal": 1466,
    "Percentage Difference": "-4.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1401,
    "Actual Price(INR)/Quintal": 1378,
    "Percentage Difference": "-1.67%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1365,
    "Actual Price(INR)/Quintal": 1396,
    "Percentage Difference": "2.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1352,
    "Actual Price(INR)/Quintal": 1421,
    "Percentage Difference": "4.86%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1381,
    "Actual Price(INR)/Quintal": 1465,
    "Percentage Difference": "5.73%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1470,
    "Actual Price(INR)/Quintal": 1550,
    "Percentage Difference": "5.16%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 1627,
    "Actual Price(INR)/Quintal": 1928,
    "Percentage Difference": "15.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 1945,
    "Actual Price(INR)/Quintal": 2269,
    "Percentage Difference": "14.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 2352,
    "Actual Price(INR)/Quintal": 2683,
    "Percentage Difference": "12.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 2827,
    "Actual Price(INR)/Quintal": 3560,
    "Percentage Difference": "20.59%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 3321,
    "Actual Price(INR)/Quintal": 3030,
    "Percentage Difference": "-9.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 2881,
    "Actual Price(INR)/Quintal": 2947,
    "Percentage Difference": "2.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 2945,
    "Actual Price(INR)/Quintal": 2795,
    "Percentage Difference": "-5.37%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2716,
    "Actual Price(INR)/Quintal": 2690,
    "Percentage Difference": "-5.37%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1064,
    "Actual Price(INR)/Quintal": 1096,
    "Percentage Difference": "2.92%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 950,
    "Actual Price(INR)/Quintal": 962,
    "Percentage Difference": "1.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1038,
    "Actual Price(INR)/Quintal": 1010,
    "Percentage Difference": "-2.77%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1032,
    "Actual Price(INR)/Quintal": 1053,
    "Percentage Difference": "1.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1028,
    "Actual Price(INR)/Quintal": 974,
    "Percentage Difference": "-5.54%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 875,
    "Actual Price(INR)/Quintal": 821,
    "Percentage Difference": "-6.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 829,
    "Actual Price(INR)/Quintal": 814,
    "Percentage Difference": "-0.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 731,
    "Actual Price(INR)/Quintal": 730,
    "Percentage Difference": "-0.14%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 737,
    "Actual Price(INR)/Quintal": 858,
    "Percentage Difference": "14.10%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 731,
    "Actual Price(INR)/Quintal": 803,
    "Percentage Difference": "8.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 814,
    "Actual Price(INR)/Quintal": 781,
    "Percentage Difference": "-4.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 792,
    "Actual Price(INR)/Quintal": 850,
    "Percentage Difference": "6.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 803,
    "Actual Price(INR)/Quintal": 809,
    "Percentage Difference": "0.74%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 811,
    "Actual Price(INR)/Quintal": 810,
    "Percentage Difference": "-0.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 823,
    "Actual Price(INR)/Quintal": 775,
    "Percentage Difference": "-6.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 792,
    "Actual Price(INR)/Quintal": 846,
    "Percentage Difference": "6.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 850,
    "Actual Price(INR)/Quintal": 850,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 858,
    "Actual Price(INR)/Quintal": 803,
    "Percentage Difference": "-6.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 833,
    "Actual Price(INR)/Quintal": 868,
    "Percentage Difference": "4.03%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 913,
    "Actual Price(INR)/Quintal": 854,
    "Percentage Difference": "-6.91%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 857,
    "Actual Price(INR)/Quintal": 791,
    "Percentage Difference": "-8.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 833,
    "Actual Price(INR)/Quintal": 802,
    "Percentage Difference": "-3.86%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 818,
    "Actual Price(INR)/Quintal": 826,
    "Percentage Difference": "0.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 839,
    "Actual Price(INR)/Quintal": 835,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 878,
    "Actual Price(INR)/Quintal": 856,
    "Percentage Difference": "-2.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 886,
    "Actual Price(INR)/Quintal": 971,
    "Percentage Difference": "8.75%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 989,
    "Actual Price(INR)/Quintal": 960,
    "Percentage Difference": "-3.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 961,
    "Actual Price(INR)/Quintal": 894,
    "Percentage Difference": "-7.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 913,
    "Actual Price(INR)/Quintal": 903,
    "Percentage Difference": "-1.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 901,
    "Actual Price(INR)/Quintal": 850,
    "Percentage Difference": "-6.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 908,
    "Actual Price(INR)/Quintal": 845,
    "Percentage Difference": "-7.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 905,
    "Actual Price(INR)/Quintal": 844,
    "Percentage Difference": "-7.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 865,
    "Actual Price(INR)/Quintal": 933,
    "Percentage Difference": "7.29%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 879,
    "Actual Price(INR)/Quintal": 872,
    "Percentage Difference": "-0.80%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 813,
    "Actual Price(INR)/Quintal": 844,
    "Percentage Difference": "3.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 783,
    "Actual Price(INR)/Quintal": 821,
    "Percentage Difference": "4.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 815,
    "Actual Price(INR)/Quintal": 827,
    "Percentage Difference": "1.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 824,
    "Actual Price(INR)/Quintal": 804,
    "Percentage Difference": "-2.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 794,
    "Actual Price(INR)/Quintal": 876,
    "Percentage Difference": "9.39%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 910,
    "Actual Price(INR)/Quintal": 938,
    "Percentage Difference": "2.98%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 928,
    "Actual Price(INR)/Quintal": 900,
    "Percentage Difference": "-3.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 916,
    "Actual Price(INR)/Quintal": 933,
    "Percentage Difference": "1.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 933,
    "Actual Price(INR)/Quintal": 942,
    "Percentage Difference": "0.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 958,
    "Actual Price(INR)/Quintal": 905,
    "Percentage Difference": "-5.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 916,
    "Actual Price(INR)/Quintal": 1014,
    "Percentage Difference": "9.66%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1028,
    "Actual Price(INR)/Quintal": 1108,
    "Percentage Difference": "7.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1115,
    "Actual Price(INR)/Quintal": 1084,
    "Percentage Difference": "-2.86%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1059,
    "Actual Price(INR)/Quintal": 1025,
    "Percentage Difference": "-3.31%",
    "YEAR": 2016
  }
];
