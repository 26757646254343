export const Paddy = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2517,"Actual Price(INR)/Quintal":2699,"Percentage Difference":"6.74%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2751,"Actual Price(INR)/Quintal":2711,"Percentage Difference":"-1.48%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2789,"Actual Price(INR)/Quintal":2764,"Percentage Difference":"-0.90%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2722,"Actual Price(INR)/Quintal":2627,"Percentage Difference":"-3.62%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2610,"Actual Price(INR)/Quintal":2549,"Percentage Difference":"-2.39%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2600,"Actual Price(INR)/Quintal":2729,"Percentage Difference":"4.73%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2596,"Actual Price(INR)/Quintal":2680,"Percentage Difference":"3.13%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2586,"Actual Price(INR)/Quintal":2650,"Percentage Difference":"2.42%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2667,"Actual Price(INR)/Quintal":2588,"Percentage Difference":"-3.05%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2465,"Actual Price(INR)/Quintal":2607,"Percentage Difference":"5.45%","YEAR":2024},
{ "Year : Month Week#":"Marwk","Predicted Price(INR)/Quintal":2486,"Actual Price(INR)/Quintal":2562,"Percentage Difference":"2.97%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2495,"Actual Price(INR)/Quintal":2563,"Percentage Difference":"2.65%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2508,"Actual Price(INR)/Quintal":2576,"Percentage Difference":"2.64%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2556,"Actual Price(INR)/Quintal":2581,"Percentage Difference":"0.97%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2482,"Actual Price(INR)/Quintal":2488,"Percentage Difference":"0.24%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2505,"Actual Price(INR)/Quintal":2474,"Percentage Difference":"-1.25%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2502,"Actual Price(INR)/Quintal":2618,"Percentage Difference":"4.43%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2534,"Actual Price(INR)/Quintal":2492,"Percentage Difference":"-1.69%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2506,"Actual Price(INR)/Quintal":2589,"Percentage Difference":"3.21%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2558,"Actual Price(INR)/Quintal":2645,"Percentage Difference":"3.29%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2575,"Actual Price(INR)/Quintal":2678,"Percentage Difference":"3.85%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2582,"Actual Price(INR)/Quintal":2708,"Percentage Difference":"4.65%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2696,"Actual Price(INR)/Quintal":2753,"Percentage Difference":"2.07%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2655,"Actual Price(INR)/Quintal":2715,"Percentage Difference":"2.21%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2709,"Actual Price(INR)/Quintal":2726,"Percentage Difference":"0.62%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2758,"Actual Price(INR)/Quintal":2720,"Percentage Difference":"-1.40%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2701,"Actual Price(INR)/Quintal":2653,"Percentage Difference":"-1.81%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2481,"Actual Price(INR)/Quintal":2547,"Percentage Difference":"2.59%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2589,"Actual Price(INR)/Quintal":2590,"Percentage Difference":"0.04%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2619,"Actual Price(INR)/Quintal":2651,"Percentage Difference":"1.21%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2612,"Actual Price(INR)/Quintal":2589,"Percentage Difference":"-0.89%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2616,"Actual Price(INR)/Quintal":2702,"Percentage Difference":"3.18%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2629,"Actual Price(INR)/Quintal":2691,"Percentage Difference":"2.30%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2623,"Actual Price(INR)/Quintal":2707,"Percentage Difference":"3.10%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2645,"Actual Price(INR)/Quintal":2639,"Percentage Difference":"-0.23%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2621,"Actual Price(INR)/Quintal":2668,"Percentage Difference":"1.76%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2670,"Actual Price(INR)/Quintal":2696,"Percentage Difference":"0.96%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2678,"Actual Price(INR)/Quintal":2777,"Percentage Difference":"3.56%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2629,"Actual Price(INR)/Quintal":2586,"Percentage Difference":"-1.66%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2612,"Actual Price(INR)/Quintal":2674,"Percentage Difference":"2.32%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2586,"Actual Price(INR)/Quintal":2584,"Percentage Difference":"-0.08%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2597,"Actual Price(INR)/Quintal":2611,"Percentage Difference":"0.54%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2551,"Actual Price(INR)/Quintal":2584,"Percentage Difference":"1.28%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2538,"Actual Price(INR)/Quintal":2559,"Percentage Difference":"0.82%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2532,"Actual Price(INR)/Quintal":2556,"Percentage Difference":"0.94%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2466,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2462,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2458,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2025,"Actual Price(INR)/Quintal":2060,"Percentage Difference":"1.70%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2106,"Actual Price(INR)/Quintal":2126,"Percentage Difference":"0.94%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2125,"Actual Price(INR)/Quintal":2176,"Percentage Difference":"2.34%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2225,"Actual Price(INR)/Quintal":2329,"Percentage Difference":"4.47%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2369,"Actual Price(INR)/Quintal":2382,"Percentage Difference":"0.55%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2363,"Actual Price(INR)/Quintal":2366,"Percentage Difference":"0.13%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2367,"Actual Price(INR)/Quintal":2475,"Percentage Difference":"4.36%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2330,"Actual Price(INR)/Quintal":2366,"Percentage Difference":"1.52%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2414,"Actual Price(INR)/Quintal":2480,"Percentage Difference":"2.66%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2357,"Actual Price(INR)/Quintal":2454,"Percentage Difference":"3.95%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2402,"Actual Price(INR)/Quintal":2439,"Percentage Difference":"1.52%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2396,"Actual Price(INR)/Quintal":2326,"Percentage Difference":"-3.01%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2271,"Actual Price(INR)/Quintal":2340,"Percentage Difference":"2.95%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2249,"Actual Price(INR)/Quintal":2213,"Percentage Difference":"-1.63%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2111,"Actual Price(INR)/Quintal":2104,"Percentage Difference":"-0.33%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2034,"Actual Price(INR)/Quintal":2097,"Percentage Difference":"3.00%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2043,"Actual Price(INR)/Quintal":2071,"Percentage Difference":"1.35%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2025,"Actual Price(INR)/Quintal":1980,"Percentage Difference":"-2.27%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1987,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"0.30%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1996,"Actual Price(INR)/Quintal":2022,"Percentage Difference":"1.29%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2122,"Actual Price(INR)/Quintal":2176,"Percentage Difference":"2.48%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2165,"Actual Price(INR)/Quintal":2253,"Percentage Difference":"3.91%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2175,"Actual Price(INR)/Quintal":2279,"Percentage Difference":"4.56%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2407,"Actual Price(INR)/Quintal":2441,"Percentage Difference":"1.39%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2448,"Actual Price(INR)/Quintal":2471,"Percentage Difference":"0.93%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2391,"Actual Price(INR)/Quintal":2509,"Percentage Difference":"4.70%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2520,"Actual Price(INR)/Quintal":2583,"Percentage Difference":"2.44%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2511,"Actual Price(INR)/Quintal":2614,"Percentage Difference":"3.94%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2544,"Actual Price(INR)/Quintal":2612,"Percentage Difference":"2.60%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2602,"Actual Price(INR)/Quintal":2582,"Percentage Difference":"-0.77%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2580,"Actual Price(INR)/Quintal":2650,"Percentage Difference":"2.64%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2645,"Actual Price(INR)/Quintal":2657,"Percentage Difference":"0.45%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2661,"Actual Price(INR)/Quintal":2668,"Percentage Difference":"0.26%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2603,"Actual Price(INR)/Quintal":2683,"Percentage Difference":"2.98%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2778,"Actual Price(INR)/Quintal":2863,"Percentage Difference":"2.97%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2764,"Actual Price(INR)/Quintal":2851,"Percentage Difference":"3.05%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2803,"Actual Price(INR)/Quintal":2804,"Percentage Difference":"0.04%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2778,"Actual Price(INR)/Quintal":2716,"Percentage Difference":"-2.28%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2838,"Actual Price(INR)/Quintal":2730,"Percentage Difference":"-3.96%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2786,"Actual Price(INR)/Quintal":2788,"Percentage Difference":"0.07%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2698,"Actual Price(INR)/Quintal":2594,"Percentage Difference":"-4.01%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2582,"Actual Price(INR)/Quintal":2619,"Percentage Difference":"1.41%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2495,"Actual Price(INR)/Quintal":2422,"Percentage Difference":"-3.01%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2401,"Actual Price(INR)/Quintal":2402,"Percentage Difference":"0.04%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2382,"Actual Price(INR)/Quintal":2477,"Percentage Difference":"3.84%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2549,"Actual Price(INR)/Quintal":2587,"Percentage Difference":"1.47%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2546,"Actual Price(INR)/Quintal":2608,"Percentage Difference":"2.38%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2562,"Actual Price(INR)/Quintal":2572,"Percentage Difference":"0.39%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1894,"Actual Price(INR)/Quintal":1944,"Percentage Difference":"2.57%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1931,"Actual Price(INR)/Quintal":2011,"Percentage Difference":"3.98%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1997,"Actual Price(INR)/Quintal":2006,"Percentage Difference":"0.45%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1992,"Actual Price(INR)/Quintal":1976,"Percentage Difference":"-0.81%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1896,"Actual Price(INR)/Quintal":1851,"Percentage Difference":"-2.43%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1931,"Actual Price(INR)/Quintal":1978,"Percentage Difference":"2.38%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1961,"Actual Price(INR)/Quintal":1983,"Percentage Difference":"1.11%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1989,"Actual Price(INR)/Quintal":2041,"Percentage Difference":"2.55%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1988,"Actual Price(INR)/Quintal":1981,"Percentage Difference":"-0.35%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1954,"Actual Price(INR)/Quintal":1977,"Percentage Difference":"1.16%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1975,"Actual Price(INR)/Quintal":2004,"Percentage Difference":"1.45%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2025,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"-1.61%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1970,"Actual Price(INR)/Quintal":1931,"Percentage Difference":"-2.02%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1933,"Actual Price(INR)/Quintal":1981,"Percentage Difference":"2.42%","YEAR":2022},
{ "Year : Month Week#":"Aprwkk3","Predicted Price(INR)/Quintal":1965,"Actual Price(INR)/Quintal":1938,"Percentage Difference":"-1.39%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1920,"Actual Price(INR)/Quintal":1865,"Percentage Difference":"-2.95%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1874,"Actual Price(INR)/Quintal":1823,"Percentage Difference":"-2.80%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1869,"Actual Price(INR)/Quintal":1826,"Percentage Difference":"-2.35%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1726,"Actual Price(INR)/Quintal":1807,"Percentage Difference":"4.48%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1796,"Actual Price(INR)/Quintal":1822,"Percentage Difference":"1.43%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1794,"Actual Price(INR)/Quintal":1815,"Percentage Difference":"1.16%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1799,"Actual Price(INR)/Quintal":1864,"Percentage Difference":"3.49%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1802,"Actual Price(INR)/Quintal":1811,"Percentage Difference":"0.50%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1845,"Actual Price(INR)/Quintal":1863,"Percentage Difference":"0.97%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1826,"Actual Price(INR)/Quintal":1901,"Percentage Difference":"3.95%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1935,"Actual Price(INR)/Quintal":2001,"Percentage Difference":"3.30%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1957,"Actual Price(INR)/Quintal":1907,"Percentage Difference":"-2.62%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1966,"Actual Price(INR)/Quintal":1943,"Percentage Difference":"-1.18%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1959,"Actual Price(INR)/Quintal":1996,"Percentage Difference":"1.85%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1984,"Actual Price(INR)/Quintal":1899,"Percentage Difference":"-4.48%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1965,"Actual Price(INR)/Quintal":2016,"Percentage Difference":"2.53%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1932,"Actual Price(INR)/Quintal":2010,"Percentage Difference":"3.88%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2055,"Actual Price(INR)/Quintal":2131,"Percentage Difference":"3.57%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2193,"Actual Price(INR)/Quintal":2295,"Percentage Difference":"4.44%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2227,"Actual Price(INR)/Quintal":2264,"Percentage Difference":"1.63%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2270,"Actual Price(INR)/Quintal":2357,"Percentage Difference":"3.69%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2333,"Actual Price(INR)/Quintal":2444,"Percentage Difference":"4.54%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2381,"Actual Price(INR)/Quintal":2410,"Percentage Difference":"1.20%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2399,"Actual Price(INR)/Quintal":2456,"Percentage Difference":"2.32%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2424,"Actual Price(INR)/Quintal":2455,"Percentage Difference":"1.26%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2474,"Actual Price(INR)/Quintal":2558,"Percentage Difference":"3.28%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2467,"Actual Price(INR)/Quintal":2533,"Percentage Difference":"2.61%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2459,"Actual Price(INR)/Quintal":2361,"Percentage Difference":"-4.15%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2049,"Actual Price(INR)/Quintal":2106,"Percentage Difference":"2.71%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2013,"Actual Price(INR)/Quintal":2103,"Percentage Difference":"4.28%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2082,"Actual Price(INR)/Quintal":2109,"Percentage Difference":"1.28%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2033,"Actual Price(INR)/Quintal":2069,"Percentage Difference":"1.74%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2036,"Actual Price(INR)/Quintal":2080,"Percentage Difference":"2.12%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1953,"Actual Price(INR)/Quintal":1947,"Percentage Difference":"-0.31%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1935,"Actual Price(INR)/Quintal":1994,"Percentage Difference":"2.96%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1954,"Actual Price(INR)/Quintal":1982,"Percentage Difference":"1.41%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1953,"Actual Price(INR)/Quintal":1989,"Percentage Difference":"1.81%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1969,"Actual Price(INR)/Quintal":1984,"Percentage Difference":"0.76%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1966,"Actual Price(INR)/Quintal":1985,"Percentage Difference":"0.96%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1978,"Actual Price(INR)/Quintal":1980,"Percentage Difference":"0.10%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1968,"Actual Price(INR)/Quintal":1972,"Percentage Difference":"0.20%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1977,"Actual Price(INR)/Quintal":1980,"Percentage Difference":"0.15%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1949,"Actual Price(INR)/Quintal":1953,"Percentage Difference":"0.20%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1946,"Actual Price(INR)/Quintal":1960,"Percentage Difference":"0.71%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1947,"Actual Price(INR)/Quintal":1920,"Percentage Difference":"-1.41%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1984,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"-0.46%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1980,"Actual Price(INR)/Quintal":1959,"Percentage Difference":"-1.07%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1963,"Actual Price(INR)/Quintal":1886,"Percentage Difference":"-4.08%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1866,"Actual Price(INR)/Quintal":1890,"Percentage Difference":"1.27%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1885,"Actual Price(INR)/Quintal":1924,"Percentage Difference":"2.03%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1847,"Actual Price(INR)/Quintal":1896,"Percentage Difference":"2.58%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1898,"Actual Price(INR)/Quintal":1895,"Percentage Difference":"-0.16%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1887,"Actual Price(INR)/Quintal":1917,"Percentage Difference":"1.56%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1842,"Actual Price(INR)/Quintal":1872,"Percentage Difference":"1.60%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1866,"Actual Price(INR)/Quintal":1895,"Percentage Difference":"1.53%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1854,"Actual Price(INR)/Quintal":1880,"Percentage Difference":"1.38%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1877,"Actual Price(INR)/Quintal":1878,"Percentage Difference":"0.05%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1878,"Actual Price(INR)/Quintal":1890,"Percentage Difference":"0.63%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1876,"Actual Price(INR)/Quintal":1909,"Percentage Difference":"1.73%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1922,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"-1.16%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1918,"Actual Price(INR)/Quintal":1929,"Percentage Difference":"0.57%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1910,"Actual Price(INR)/Quintal":1904,"Percentage Difference":"-0.32%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1903,"Actual Price(INR)/Quintal":1882,"Percentage Difference":"-1.12%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1903,"Actual Price(INR)/Quintal":1915,"Percentage Difference":"0.63%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":1891,"Percentage Difference":"-0.95%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1889,"Actual Price(INR)/Quintal":1892,"Percentage Difference":"0.16%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1895,"Actual Price(INR)/Quintal":1899,"Percentage Difference":"0.21%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1908,"Actual Price(INR)/Quintal":1918,"Percentage Difference":"0.52%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1936,"Actual Price(INR)/Quintal":1911,"Percentage Difference":"-1.31%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1904,"Actual Price(INR)/Quintal":1983,"Percentage Difference":"3.98%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1903,"Actual Price(INR)/Quintal":1966,"Percentage Difference":"3.20%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1899,"Actual Price(INR)/Quintal":1949,"Percentage Difference":"2.57%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1851,"Actual Price(INR)/Quintal":1885,"Percentage Difference":"1.80%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1889,"Actual Price(INR)/Quintal":1894,"Percentage Difference":"0.26%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1890,"Actual Price(INR)/Quintal":1862,"Percentage Difference":"-1.50%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1868,"Actual Price(INR)/Quintal":1912,"Percentage Difference":"2.30%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1872,"Actual Price(INR)/Quintal":1882,"Percentage Difference":"0.53%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1912,"Actual Price(INR)/Quintal":1868,"Percentage Difference":"-2.36%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1854,"Actual Price(INR)/Quintal":1904,"Percentage Difference":"2.63%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1849,"Actual Price(INR)/Quintal":1878,"Percentage Difference":"1.54%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1897,"Actual Price(INR)/Quintal":1955,"Percentage Difference":"2.97%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1950,"Actual Price(INR)/Quintal":1909,"Percentage Difference":"-2.15%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1954,"Actual Price(INR)/Quintal":1912,"Percentage Difference":"-2.20%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1956,"Actual Price(INR)/Quintal":1927,"Percentage Difference":"-1.50%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1902,"Actual Price(INR)/Quintal":1963,"Percentage Difference":"3.11%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1889,"Actual Price(INR)/Quintal":1983,"Percentage Difference":"4.74%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1893,"Actual Price(INR)/Quintal":1857,"Percentage Difference":"-1.94%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1899,"Actual Price(INR)/Quintal":1952,"Percentage Difference":"2.72%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1895,"Actual Price(INR)/Quintal":1958,"Percentage Difference":"3.22%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1936,"Actual Price(INR)/Quintal":1977,"Percentage Difference":"2.07%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1935,"Actual Price(INR)/Quintal":1996,"Percentage Difference":"3.06%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1934,"Actual Price(INR)/Quintal":1954,"Percentage Difference":"1.02%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1933,"Actual Price(INR)/Quintal":2001,"Percentage Difference":"3.40%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1993,"Actual Price(INR)/Quintal":1942,"Percentage Difference":"-2.63%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1995,"Actual Price(INR)/Quintal":2005,"Percentage Difference":"0.50%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1997,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"-0.20%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1994,"Actual Price(INR)/Quintal":2003,"Percentage Difference":"0.45%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1986,"Actual Price(INR)/Quintal":1997,"Percentage Difference":"0.55%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1985,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"0.40%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1983,"Actual Price(INR)/Quintal":1963,"Percentage Difference":"-1.02%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1972,"Actual Price(INR)/Quintal":1965,"Percentage Difference":"-0.36%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":1966,"Actual Price(INR)/Quintal":1927,"Percentage Difference":"-2.02%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":1933,"Actual Price(INR)/Quintal":1907,"Percentage Difference":"-1.36%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":1833,"Percentage Difference":"-4.15%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1839,"Actual Price(INR)/Quintal":1883,"Percentage Difference":"2.34%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":1862,"Actual Price(INR)/Quintal":1889,"Percentage Difference":"1.43%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":1867,"Actual Price(INR)/Quintal":1885,"Percentage Difference":"0.95%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":1893,"Actual Price(INR)/Quintal":1903,"Percentage Difference":"0.53%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":1886,"Actual Price(INR)/Quintal":1906,"Percentage Difference":"1.05%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":1894,"Actual Price(INR)/Quintal":1917,"Percentage Difference":"1.20%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":1923,"Actual Price(INR)/Quintal":1952,"Percentage Difference":"1.49%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":1901,"Actual Price(INR)/Quintal":1930,"Percentage Difference":"1.50%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":1916,"Actual Price(INR)/Quintal":1935,"Percentage Difference":"0.98%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1923,"Actual Price(INR)/Quintal":1929,"Percentage Difference":"0.31%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1862,"Actual Price(INR)/Quintal":1888,"Percentage Difference":"1.38%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1874,"Actual Price(INR)/Quintal":1881,"Percentage Difference":"0.37%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":1871,"Actual Price(INR)/Quintal":1872,"Percentage Difference":"0.05%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":1868,"Actual Price(INR)/Quintal":1898,"Percentage Difference":"1.58%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":1884,"Actual Price(INR)/Quintal":1904,"Percentage Difference":"1.05%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":1896,"Actual Price(INR)/Quintal":1921,"Percentage Difference":"1.30%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":1891,"Actual Price(INR)/Quintal":1917,"Percentage Difference":"1.36%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":1898,"Actual Price(INR)/Quintal":1877,"Percentage Difference":"-1.12%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":1838,"Actual Price(INR)/Quintal":1844,"Percentage Difference":"0.33%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":1808,"Actual Price(INR)/Quintal":1845,"Percentage Difference":"2.01%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":1801,"Actual Price(INR)/Quintal":1792,"Percentage Difference":"-0.50%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":1815,"Actual Price(INR)/Quintal":1812,"Percentage Difference":"-0.17%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":1816,"Actual Price(INR)/Quintal":1823,"Percentage Difference":"0.38%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":1920,"Actual Price(INR)/Quintal":1922,"Percentage Difference":"0.10%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":1930,"Actual Price(INR)/Quintal":1907,"Percentage Difference":"-1.21%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2034,
    "Actual Price(INR)/Quintal": 2005,
    "Percentage Difference": "-1.45%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2008,
    "Actual Price(INR)/Quintal": 1952,
    "Percentage Difference": "-2.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1956,
    "Actual Price(INR)/Quintal": 1953,
    "Percentage Difference": "-0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1915,
    "Actual Price(INR)/Quintal": 2028,
    "Percentage Difference": "5.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1913,
    "Actual Price(INR)/Quintal": 2025,
    "Percentage Difference": "5.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 2023,
    "Actual Price(INR)/Quintal": 2074,
    "Percentage Difference": "2.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 2018,
    "Actual Price(INR)/Quintal": 2040,
    "Percentage Difference": "1.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 2010,
    "Actual Price(INR)/Quintal": 2095,
    "Percentage Difference": "4.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 2009,
    "Actual Price(INR)/Quintal": 2103,
    "Percentage Difference": "4.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 2004,
    "Actual Price(INR)/Quintal": 2083,
    "Percentage Difference": "3.79%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 2002,
    "Actual Price(INR)/Quintal": 2114,
    "Percentage Difference": "5.30%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 2107,
    "Actual Price(INR)/Quintal": 2143,
    "Percentage Difference": "1.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 2120,
    "Actual Price(INR)/Quintal": 2128,
    "Percentage Difference": "0.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 2145,
    "Actual Price(INR)/Quintal": 2009,
    "Percentage Difference": "-6.77%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 2056,
    "Actual Price(INR)/Quintal": 2015,
    "Percentage Difference": "-2.03%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1982,
    "Actual Price(INR)/Quintal": 1933,
    "Percentage Difference": "-2.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1920,
    "Actual Price(INR)/Quintal": 1878,
    "Percentage Difference": "-2.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1902,
    "Actual Price(INR)/Quintal": 1824,
    "Percentage Difference": "-4.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1819,
    "Actual Price(INR)/Quintal": 1850,
    "Percentage Difference": "1.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1808,
    "Actual Price(INR)/Quintal": 1903,
    "Percentage Difference": "4.99%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1857,
    "Actual Price(INR)/Quintal": 1922,
    "Percentage Difference": "3.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1906,
    "Actual Price(INR)/Quintal": 2015,
    "Percentage Difference": "5.41%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1996,
    "Actual Price(INR)/Quintal": 2011,
    "Percentage Difference": "0.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1983,
    "Actual Price(INR)/Quintal": 1995,
    "Percentage Difference": "0.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1926,
    "Actual Price(INR)/Quintal": 1945,
    "Percentage Difference": "0.98%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1916,
    "Actual Price(INR)/Quintal": 1955,
    "Percentage Difference": "1.99%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1945,
    "Actual Price(INR)/Quintal": 2009,
    "Percentage Difference": "3.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1944,
    "Actual Price(INR)/Quintal": 1914,
    "Percentage Difference": "-1.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1906,
    "Actual Price(INR)/Quintal": 1946,
    "Percentage Difference": "2.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1916,
    "Actual Price(INR)/Quintal": 1990,
    "Percentage Difference": "3.72%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2040,
    "Percentage Difference": "0.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2020,
    "Actual Price(INR)/Quintal": 2016,
    "Percentage Difference": "-0.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1997,
    "Actual Price(INR)/Quintal": 2005,
    "Percentage Difference": "0.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2004,
    "Actual Price(INR)/Quintal": 2060,
    "Percentage Difference": "2.72%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2007,
    "Actual Price(INR)/Quintal": 2040,
    "Percentage Difference": "1.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2001,
    "Actual Price(INR)/Quintal": 2020,
    "Percentage Difference": "0.94%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 1994,
    "Actual Price(INR)/Quintal": 1966,
    "Percentage Difference": "-1.42%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1989,
    "Actual Price(INR)/Quintal": 1953,
    "Percentage Difference": "-1.84%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 1979,
    "Actual Price(INR)/Quintal": 2022,
    "Percentage Difference": "2.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1940,
    "Actual Price(INR)/Quintal": 1925,
    "Percentage Difference": "-0.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1975,
    "Actual Price(INR)/Quintal": 1986,
    "Percentage Difference": "0.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1955,
    "Actual Price(INR)/Quintal": 1899,
    "Percentage Difference": "-2.95%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1941,
    "Actual Price(INR)/Quintal": 1960,
    "Percentage Difference": "0.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1936,
    "Actual Price(INR)/Quintal": 1997,
    "Percentage Difference": "3.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1916,
    "Actual Price(INR)/Quintal": 1904,
    "Percentage Difference": "-0.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1900,
    "Actual Price(INR)/Quintal": 1864,
    "Percentage Difference": "-1.93%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1882,
    "Actual Price(INR)/Quintal": 1901,
    "Percentage Difference": "1.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1922,
    "Actual Price(INR)/Quintal": 1888,
    "Percentage Difference": "-1.80%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2107,
    "Actual Price(INR)/Quintal": 2125,
    "Percentage Difference": "0.85%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2108,
    "Actual Price(INR)/Quintal": 2158,
    "Percentage Difference": "2.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 2146,
    "Actual Price(INR)/Quintal": 2136,
    "Percentage Difference": "-0.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 2104,
    "Actual Price(INR)/Quintal": 2198,
    "Percentage Difference": "4.28%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 2194,
    "Actual Price(INR)/Quintal": 2180,
    "Percentage Difference": "-0.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 2188,
    "Actual Price(INR)/Quintal": 2146,
    "Percentage Difference": "-1.96%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 2132,
    "Actual Price(INR)/Quintal": 2110,
    "Percentage Difference": "-1.04%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 2106,
    "Actual Price(INR)/Quintal": 2215,
    "Percentage Difference": "4.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 2163,
    "Actual Price(INR)/Quintal": 2183,
    "Percentage Difference": "0.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 2227,
    "Actual Price(INR)/Quintal": 2262,
    "Percentage Difference": "1.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 2247,
    "Actual Price(INR)/Quintal": 2207,
    "Percentage Difference": "-1.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 2202,
    "Actual Price(INR)/Quintal": 2159,
    "Percentage Difference": "-1.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 2231,
    "Actual Price(INR)/Quintal": 2145,
    "Percentage Difference": "-4.01%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 2120,
    "Actual Price(INR)/Quintal": 1928,
    "Percentage Difference": "-9.96%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 2027,
    "Actual Price(INR)/Quintal": 1935,
    "Percentage Difference": "-4.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1921,
    "Actual Price(INR)/Quintal": 1894,
    "Percentage Difference": "-1.43%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1993,
    "Actual Price(INR)/Quintal": 1931,
    "Percentage Difference": "-3.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1880,
    "Actual Price(INR)/Quintal": 1871,
    "Percentage Difference": "-0.48%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1926,
    "Actual Price(INR)/Quintal": 1852,
    "Percentage Difference": "-4.00%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1838,
    "Actual Price(INR)/Quintal": 1843,
    "Percentage Difference": "0.27%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1821,
    "Actual Price(INR)/Quintal": 1888,
    "Percentage Difference": "3.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1886,
    "Actual Price(INR)/Quintal": 1947,
    "Percentage Difference": "3.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1917,
    "Actual Price(INR)/Quintal": 1968,
    "Percentage Difference": "2.59%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1966,
    "Actual Price(INR)/Quintal": 1972,
    "Percentage Difference": "0.30%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1955,
    "Actual Price(INR)/Quintal": 2011,
    "Percentage Difference": "2.78%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2057,
    "Actual Price(INR)/Quintal": 2006,
    "Percentage Difference": "-2.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2055,
    "Actual Price(INR)/Quintal": 2047,
    "Percentage Difference": "-0.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2053,
    "Actual Price(INR)/Quintal": 1941,
    "Percentage Difference": "-5.77%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2069,
    "Actual Price(INR)/Quintal": 1916,
    "Percentage Difference": "-7.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1909,
    "Actual Price(INR)/Quintal": 1979,
    "Percentage Difference": "3.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1918,
    "Actual Price(INR)/Quintal": 2078,
    "Percentage Difference": "7.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2053,
    "Actual Price(INR)/Quintal": 2035,
    "Percentage Difference": "-0.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2064,
    "Actual Price(INR)/Quintal": 2040,
    "Percentage Difference": "-1.18%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2014,
    "Percentage Difference": "-0.94%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2030,
    "Actual Price(INR)/Quintal": 2075,
    "Percentage Difference": "2.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2029,
    "Actual Price(INR)/Quintal": 2057,
    "Percentage Difference": "1.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2064,
    "Actual Price(INR)/Quintal": 2092,
    "Percentage Difference": "1.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2065,
    "Actual Price(INR)/Quintal": 2090,
    "Percentage Difference": "1.20%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2066,
    "Actual Price(INR)/Quintal": 2008,
    "Percentage Difference": "-2.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 2015,
    "Actual Price(INR)/Quintal": 2024,
    "Percentage Difference": "0.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 2013,
    "Actual Price(INR)/Quintal": 1927,
    "Percentage Difference": "-4.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1916,
    "Actual Price(INR)/Quintal": 1932,
    "Percentage Difference": "0.83%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1833,
    "Actual Price(INR)/Quintal": 1910,
    "Percentage Difference": "4.03%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1841,
    "Actual Price(INR)/Quintal": 1871,
    "Percentage Difference": "1.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1804,
    "Actual Price(INR)/Quintal": 1850,
    "Percentage Difference": "2.49%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1813,
    "Actual Price(INR)/Quintal": 1938,
    "Percentage Difference": "6.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1928,
    "Actual Price(INR)/Quintal": 1951,
    "Percentage Difference": "1.18%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1913,
    "Actual Price(INR)/Quintal": 1993,
    "Percentage Difference": "4.01%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1956,
    "Actual Price(INR)/Quintal": 2029,
    "Percentage Difference": "3.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1939,
    "Actual Price(INR)/Quintal": 2067,
    "Percentage Difference": "6.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 2021,
    "Actual Price(INR)/Quintal": 2018,
    "Percentage Difference": "-0.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 2007,
    "Actual Price(INR)/Quintal": 2153,
    "Percentage Difference": "6.78%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 2131,
    "Actual Price(INR)/Quintal": 2180,
    "Percentage Difference": "2.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 2109,
    "Actual Price(INR)/Quintal": 2221,
    "Percentage Difference": "5.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 2199,
    "Actual Price(INR)/Quintal": 2200,
    "Percentage Difference": "0.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 2192,
    "Actual Price(INR)/Quintal": 2216,
    "Percentage Difference": "1.08%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 2215,
    "Actual Price(INR)/Quintal": 2255,
    "Percentage Difference": "1.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 2225,
    "Actual Price(INR)/Quintal": 2239,
    "Percentage Difference": "0.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 2220,
    "Actual Price(INR)/Quintal": 2252,
    "Percentage Difference": "1.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 2212,
    "Actual Price(INR)/Quintal": 2260,
    "Percentage Difference": "2.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 2232,
    "Actual Price(INR)/Quintal": 2311,
    "Percentage Difference": "3.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 2247,
    "Actual Price(INR)/Quintal": 2135,
    "Percentage Difference": "-5.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 2044,
    "Actual Price(INR)/Quintal": 2044,
    "Percentage Difference": "0.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 2046,
    "Actual Price(INR)/Quintal": 1971,
    "Percentage Difference": "-3.81%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1969,
    "Actual Price(INR)/Quintal": 1974,
    "Percentage Difference": "0.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1971,
    "Actual Price(INR)/Quintal": 1886,
    "Percentage Difference": "-4.51%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1876,
    "Actual Price(INR)/Quintal": 1975,
    "Percentage Difference": "5.01%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1977,
    "Actual Price(INR)/Quintal": 1979,
    "Percentage Difference": "0.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1972,
    "Actual Price(INR)/Quintal": 2058,
    "Percentage Difference": "4.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 2070,
    "Actual Price(INR)/Quintal": 2106,
    "Percentage Difference": "1.71%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 2114,
    "Actual Price(INR)/Quintal": 2073,
    "Percentage Difference": "-1.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 2019,
    "Actual Price(INR)/Quintal": 2108,
    "Percentage Difference": "4.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 2150,
    "Actual Price(INR)/Quintal": 2152,
    "Percentage Difference": "0.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 2108,
    "Actual Price(INR)/Quintal": 2176,
    "Percentage Difference": "3.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 2170,
    "Actual Price(INR)/Quintal": 2181,
    "Percentage Difference": "0.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 2176,
    "Actual Price(INR)/Quintal": 2236,
    "Percentage Difference": "0.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 2190,
    "Actual Price(INR)/Quintal": 2220,
    "Percentage Difference": "1.35%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 2218,
    "Actual Price(INR)/Quintal": 2254,
    "Percentage Difference": "1.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 2220,
    "Actual Price(INR)/Quintal": 2264,
    "Percentage Difference": "1.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 2222,
    "Actual Price(INR)/Quintal": 2306,
    "Percentage Difference": "3.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 2212,
    "Actual Price(INR)/Quintal": 2296,
    "Percentage Difference": "3.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 2295,
    "Actual Price(INR)/Quintal": 2242,
    "Percentage Difference": "-2.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 2244,
    "Actual Price(INR)/Quintal": 2245,
    "Percentage Difference": "0.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 2264,
    "Actual Price(INR)/Quintal": 2344,
    "Percentage Difference": "3.41%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 2228,
    "Actual Price(INR)/Quintal": 2321,
    "Percentage Difference": "4.01%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 2235,
    "Actual Price(INR)/Quintal": 2290,
    "Percentage Difference": "2.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 2241,
    "Actual Price(INR)/Quintal": 2268,
    "Percentage Difference": "1.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 2234,
    "Actual Price(INR)/Quintal": 2241,
    "Percentage Difference": "0.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 2241,
    "Actual Price(INR)/Quintal": 2137,
    "Percentage Difference": "-4.87%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 2036,
    "Actual Price(INR)/Quintal": 1971,
    "Percentage Difference": "-3.30%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 1918,
    "Actual Price(INR)/Quintal": 1948,
    "Percentage Difference": "1.54%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 1967,
    "Actual Price(INR)/Quintal": 1968,
    "Percentage Difference": "0.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1940,
    "Actual Price(INR)/Quintal": 2009,
    "Percentage Difference": "3.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1994,
    "Actual Price(INR)/Quintal": 2008,
    "Percentage Difference": "0.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1973,
    "Actual Price(INR)/Quintal": 2062,
    "Percentage Difference": "4.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2063,
    "Actual Price(INR)/Quintal": 2106,
    "Percentage Difference": "2.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1892,
    "Actual Price(INR)/Quintal": 1906,
    "Percentage Difference": "0.73%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1932,
    "Actual Price(INR)/Quintal": 1829,
    "Percentage Difference": "-5.63%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1884,
    "Actual Price(INR)/Quintal": 1888,
    "Percentage Difference": "0.21%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1927,
    "Actual Price(INR)/Quintal": 1919,
    "Percentage Difference": "-0.42%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1853,
    "Actual Price(INR)/Quintal": 2013,
    "Percentage Difference": "7.95%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1886,
    "Actual Price(INR)/Quintal": 1897,
    "Percentage Difference": "0.58%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1935,
    "Actual Price(INR)/Quintal": 2028,
    "Percentage Difference": "4.59%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1930,
    "Actual Price(INR)/Quintal": 1984,
    "Percentage Difference": "2.72%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1882,
    "Actual Price(INR)/Quintal": 2140,
    "Percentage Difference": "12.06%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 2034,
    "Actual Price(INR)/Quintal": 2135,
    "Percentage Difference": "4.73%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 2086,
    "Actual Price(INR)/Quintal": 2128,
    "Percentage Difference": "1.97%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 2105,
    "Actual Price(INR)/Quintal": 2079,
    "Percentage Difference": "-1.25%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 2123,
    "Actual Price(INR)/Quintal": 2045,
    "Percentage Difference": "-3.84%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 2072,
    "Actual Price(INR)/Quintal": 1930,
    "Percentage Difference": "-7.36%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1918,
    "Actual Price(INR)/Quintal": 1902,
    "Percentage Difference": "-0.01%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1937,
    "Actual Price(INR)/Quintal": 1877,
    "Percentage Difference": "-3.19%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1898,
    "Actual Price(INR)/Quintal": 1997,
    "Percentage Difference": "4.96%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1922,
    "Actual Price(INR)/Quintal": 2092,
    "Percentage Difference": "8.13%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2086,
    "Percentage Difference": "2.54%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 2030,
    "Actual Price(INR)/Quintal": 2103,
    "Percentage Difference": "3.47%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 2114,
    "Actual Price(INR)/Quintal": 2164,
    "Percentage Difference": "2.31%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 2084,
    "Actual Price(INR)/Quintal": 2099,
    "Percentage Difference": "0.71%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2121,
    "Percentage Difference": "4.15%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 2073,
    "Actual Price(INR)/Quintal": 2073,
    "Percentage Difference": "0.00%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 2075,
    "Actual Price(INR)/Quintal": 2180,
    "Percentage Difference": "4.82%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 2136,
    "Actual Price(INR)/Quintal": 2179,
    "Percentage Difference": "1.19%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 2139,
    "Actual Price(INR)/Quintal": 2151,
    "Percentage Difference": "0.56%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 2144,
    "Actual Price(INR)/Quintal": 2195,
    "Percentage Difference": "2.32%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 2165,
    "Actual Price(INR)/Quintal": 2161,
    "Percentage Difference": "-0.18%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 2173,
    "Actual Price(INR)/Quintal": 2318,
    "Percentage Difference": "6.25%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 2275,
    "Actual Price(INR)/Quintal": 2217,
    "Percentage Difference": "-2.61%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 2251,
    "Actual Price(INR)/Quintal": 2265,
    "Percentage Difference": "0.62%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 2267,
    "Actual Price(INR)/Quintal": 2301,
    "Percentage Difference": "1.48%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 2104,
    "Actual Price(INR)/Quintal": 2133,
    "Percentage Difference": "1.36%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 2001,
    "Actual Price(INR)/Quintal": 2158,
    "Percentage Difference": "7.27%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 2040,
    "Actual Price(INR)/Quintal": 2157,
    "Percentage Difference": "5.42%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 2164,
    "Actual Price(INR)/Quintal": 2227,
    "Percentage Difference": "2.83%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 2242,
    "Actual Price(INR)/Quintal": 2264,
    "Percentage Difference": "0.97%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 2224,
    "Actual Price(INR)/Quintal": 2255,
    "Percentage Difference": "1.37%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 2213,
    "Actual Price(INR)/Quintal": 2296,
    "Percentage Difference": "3.62%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 2209,
    "Actual Price(INR)/Quintal": 2195,
    "Percentage Difference": "-0.64%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 2210,
    "Actual Price(INR)/Quintal": 2166,
    "Percentage Difference": "-2.03%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 2212,
    "Actual Price(INR)/Quintal": 2084,
    "Percentage Difference": "-6.42%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 2013,
    "Actual Price(INR)/Quintal": 1992,
    "Percentage Difference": "-1.05%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1997,
    "Actual Price(INR)/Quintal": 1926,
    "Percentage Difference": "-3.69%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1929,
    "Actual Price(INR)/Quintal": 1979,
    "Percentage Difference": "2.53%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1954,
    "Actual Price(INR)/Quintal": 2007,
    "Percentage Difference": "2.64%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2004,
    "Actual Price(INR)/Quintal": 2006,
    "Percentage Difference": "0.01%",
    "YEAR": ""
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1892,
    "Actual Price(INR)/Quintal": 1906,
    "Percentage Difference": "0.73%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1932,
    "Actual Price(INR)/Quintal": 1829,
    "Percentage Difference": "-5.63%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1884,
    "Actual Price(INR)/Quintal": 1888,
    "Percentage Difference": "0.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1927,
    "Actual Price(INR)/Quintal": 1919,
    "Percentage Difference": "-0.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1853,
    "Actual Price(INR)/Quintal": 2013,
    "Percentage Difference": "7.95%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1886,
    "Actual Price(INR)/Quintal": 1897,
    "Percentage Difference": "0.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1935,
    "Actual Price(INR)/Quintal": 2028,
    "Percentage Difference": "4.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1930,
    "Actual Price(INR)/Quintal": 1984,
    "Percentage Difference": "2.72%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1882,
    "Actual Price(INR)/Quintal": 2140,
    "Percentage Difference": "12.06%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 2034,
    "Actual Price(INR)/Quintal": 2135,
    "Percentage Difference": "4.73%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 2086,
    "Actual Price(INR)/Quintal": 2128,
    "Percentage Difference": "1.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 2105,
    "Actual Price(INR)/Quintal": 2079,
    "Percentage Difference": "-1.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 2123,
    "Actual Price(INR)/Quintal": 2045,
    "Percentage Difference": "-3.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 2072,
    "Actual Price(INR)/Quintal": 1930,
    "Percentage Difference": "-7.36%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1918,
    "Actual Price(INR)/Quintal": 1902,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1937,
    "Actual Price(INR)/Quintal": 1877,
    "Percentage Difference": "-3.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1898,
    "Actual Price(INR)/Quintal": 1997,
    "Percentage Difference": "4.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1922,
    "Actual Price(INR)/Quintal": 2092,
    "Percentage Difference": "8.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2086,
    "Percentage Difference": "2.54%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 2030,
    "Actual Price(INR)/Quintal": 2103,
    "Percentage Difference": "3.47%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 2114,
    "Actual Price(INR)/Quintal": 2164,
    "Percentage Difference": "2.31%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 2084,
    "Actual Price(INR)/Quintal": 2099,
    "Percentage Difference": "0.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2121,
    "Percentage Difference": "4.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 2073,
    "Actual Price(INR)/Quintal": 2073,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 2075,
    "Actual Price(INR)/Quintal": 2180,
    "Percentage Difference": "4.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 2136,
    "Actual Price(INR)/Quintal": 2179,
    "Percentage Difference": "1.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 2139,
    "Actual Price(INR)/Quintal": 2151,
    "Percentage Difference": "0.56%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 2144,
    "Actual Price(INR)/Quintal": 2195,
    "Percentage Difference": "2.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 2165,
    "Actual Price(INR)/Quintal": 2161,
    "Percentage Difference": "-0.18%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 2173,
    "Actual Price(INR)/Quintal": 2318,
    "Percentage Difference": "6.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 2275,
    "Actual Price(INR)/Quintal": 2217,
    "Percentage Difference": "-2.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 2251,
    "Actual Price(INR)/Quintal": 2265,
    "Percentage Difference": "0.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 2267,
    "Actual Price(INR)/Quintal": 2301,
    "Percentage Difference": "1.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 2104,
    "Actual Price(INR)/Quintal": 2133,
    "Percentage Difference": "1.36%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 2001,
    "Actual Price(INR)/Quintal": 2158,
    "Percentage Difference": "7.27%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 2040,
    "Actual Price(INR)/Quintal": 2157,
    "Percentage Difference": "5.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 2164,
    "Actual Price(INR)/Quintal": 2227,
    "Percentage Difference": "2.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 2242,
    "Actual Price(INR)/Quintal": 2264,
    "Percentage Difference": "0.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 2224,
    "Actual Price(INR)/Quintal": 2255,
    "Percentage Difference": "1.37%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 2213,
    "Actual Price(INR)/Quintal": 2296,
    "Percentage Difference": "3.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 2209,
    "Actual Price(INR)/Quintal": 2195,
    "Percentage Difference": "-0.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 2210,
    "Actual Price(INR)/Quintal": 2166,
    "Percentage Difference": "-2.03%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 2212,
    "Actual Price(INR)/Quintal": 2084,
    "Percentage Difference": "-6.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 2013,
    "Actual Price(INR)/Quintal": 1992,
    "Percentage Difference": "-1.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1997,
    "Actual Price(INR)/Quintal": 1926,
    "Percentage Difference": "-3.69%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1929,
    "Actual Price(INR)/Quintal": 1979,
    "Percentage Difference": "2.53%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1954,
    "Actual Price(INR)/Quintal": 2007,
    "Percentage Difference": "2.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2004,
    "Actual Price(INR)/Quintal": 2006,
    "Percentage Difference": "0.01%",
    "YEAR": 2016
  }
];
