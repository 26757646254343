export const Blackgram = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":8124,"Actual Price(INR)/Quintal":8071,"Percentage Difference":"-0.66%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":8237,"Actual Price(INR)/Quintal":8174,"Percentage Difference":"-0.77%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":7977,"Actual Price(INR)/Quintal":7954,"Percentage Difference":"-0.29%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":7954,"Actual Price(INR)/Quintal":8602,"Percentage Difference":"7.53%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":8613,"Actual Price(INR)/Quintal":8441,"Percentage Difference":"-4.41%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":8617,"Actual Price(INR)/Quintal":8956,"Percentage Difference":"3.79%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":8600,"Actual Price(INR)/Quintal":8366,"Percentage Difference":"-2.80%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":8398,"Actual Price(INR)/Quintal":8101,"Percentage Difference":"-3.67%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":8025,"Actual Price(INR)/Quintal":8080,"Percentage Difference":"0.68%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":8060,"Actual Price(INR)/Quintal":8175,"Percentage Difference":"1.41%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":8018,"Actual Price(INR)/Quintal":8192,"Percentage Difference":"2.12%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":8013,"Actual Price(INR)/Quintal":8275,"Percentage Difference":"3.17%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":8091,"Actual Price(INR)/Quintal":8288,"Percentage Difference":"2.38%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":8058,"Actual Price(INR)/Quintal":7900,"Percentage Difference":"-2.00%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":8110,"Actual Price(INR)/Quintal":7980,"Percentage Difference":"-1.63%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":8086,"Actual Price(INR)/Quintal":8322,"Percentage Difference":"2.84%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":8023,"Actual Price(INR)/Quintal":7900,"Percentage Difference":"-1.56%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":7820,"Actual Price(INR)/Quintal":7866,"Percentage Difference":"0.58%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":8082,"Actual Price(INR)/Quintal":8250,"Percentage Difference":"2.04%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":8037,"Actual Price(INR)/Quintal":8040,"Percentage Difference":"0.04%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":8038,"Actual Price(INR)/Quintal":7750,"Percentage Difference":"-3.72%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":7894,"Actual Price(INR)/Quintal":8089,"Percentage Difference":"2.41%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":7836,"Actual Price(INR)/Quintal":7851,"Percentage Difference":"0.19%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":7718,"Actual Price(INR)/Quintal":7550,"Percentage Difference":"-2.23%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":7417,"Actual Price(INR)/Quintal":7400,"Percentage Difference":"-0.23%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":7411,"Actual Price(INR)/Quintal":7600,"Percentage Difference":"2.49%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":7638,"Actual Price(INR)/Quintal":7629,"Percentage Difference":"-0.12%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":7651,"Actual Price(INR)/Quintal":7712,"Percentage Difference":"0.79%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":7654,"Actual Price(INR)/Quintal":7500,"Percentage Difference":"-2.05%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":7314,"Actual Price(INR)/Quintal":7556,"Percentage Difference":"3.20%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":7317,"Actual Price(INR)/Quintal":7516,"Percentage Difference":"2.65%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":7342,"Actual Price(INR)/Quintal":7720,"Percentage Difference":"4.90%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":7749,"Actual Price(INR)/Quintal":7826,"Percentage Difference":"0.98%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":7279,"Actual Price(INR)/Quintal":7079,"Percentage Difference":"-2.83%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6841,"Actual Price(INR)/Quintal":6696,"Percentage Difference":"-2.17%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6629,"Actual Price(INR)/Quintal":6301,"Percentage Difference":"-5.21%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6191,"Actual Price(INR)/Quintal":6166,"Percentage Difference":"-0.41%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6443,"Actual Price(INR)/Quintal":6805,"Percentage Difference":"5.32%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":6477,"Actual Price(INR)/Quintal":6255,"Percentage Difference":"-3.55%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6132,"Actual Price(INR)/Quintal":6072,"Percentage Difference":"-0.99%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6110,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"1.45%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6119,"Actual Price(INR)/Quintal":6322,"Percentage Difference":"3.21%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6244,"Actual Price(INR)/Quintal":6193,"Percentage Difference":"-0.82%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":6157,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"0.69%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6130,"Actual Price(INR)/Quintal":6211,"Percentage Difference":"1.30%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6181,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6098,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6070,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":6113,"Actual Price(INR)/Quintal":6137,"Percentage Difference":"0.39%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6140,"Actual Price(INR)/Quintal":6150,"Percentage Difference":"0.16%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":6074,"Actual Price(INR)/Quintal":6155,"Percentage Difference":"1.32%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":6140,"Actual Price(INR)/Quintal":6155,"Percentage Difference":"0.24%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6143,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"-0.70%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6149,"Actual Price(INR)/Quintal":6250,"Percentage Difference":"1.62%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":6152,"Actual Price(INR)/Quintal":6162,"Percentage Difference":"0.16%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":6106,"Actual Price(INR)/Quintal":6450,"Percentage Difference":"5.33%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6470,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"-4.35%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6254,"Actual Price(INR)/Quintal":6564,"Percentage Difference":"4.72%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6455,"Actual Price(INR)/Quintal":6481,"Percentage Difference":"0.40%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6436,"Actual Price(INR)/Quintal":6444,"Percentage Difference":"0.12%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6333,"Actual Price(INR)/Quintal":6511,"Percentage Difference":"2.73%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6446,"Actual Price(INR)/Quintal":6612,"Percentage Difference":"2.51%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":6659,"Actual Price(INR)/Quintal":6466,"Percentage Difference":"-2.98%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6401,"Actual Price(INR)/Quintal":6433,"Percentage Difference":"0.50%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6474,"Actual Price(INR)/Quintal":6483,"Percentage Difference":"0.14%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6471,"Actual Price(INR)/Quintal":6555,"Percentage Difference":"1.28%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6443,"Actual Price(INR)/Quintal":6540,"Percentage Difference":"1.48%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6451,"Actual Price(INR)/Quintal":6500,"Percentage Difference":"0.75%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6444,"Actual Price(INR)/Quintal":6533,"Percentage Difference":"1.36%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6515,"Actual Price(INR)/Quintal":6450,"Percentage Difference":"-1.01%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":6523,"Actual Price(INR)/Quintal":6500,"Percentage Difference":"-0.35%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6527,"Actual Price(INR)/Quintal":6610,"Percentage Difference":"1.26%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6521,"Actual Price(INR)/Quintal":6596,"Percentage Difference":"1.14%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6455,"Actual Price(INR)/Quintal":6258,"Percentage Difference":"-3.15%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6215,"Actual Price(INR)/Quintal":6412,"Percentage Difference":"3.07%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6215,"Actual Price(INR)/Quintal":6568,"Percentage Difference":"5.37%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6455,"Actual Price(INR)/Quintal":6650,"Percentage Difference":"2.93%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6410,"Actual Price(INR)/Quintal":6401,"Percentage Difference":"-0.14%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6406,"Actual Price(INR)/Quintal":6287,"Percentage Difference":"-1.89%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6346,"Actual Price(INR)/Quintal":6544,"Percentage Difference":"3.03%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6618,"Actual Price(INR)/Quintal":7440,"Percentage Difference":"11.05%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":8389,"Actual Price(INR)/Quintal":9016,"Percentage Difference":"6.95%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":9061,"Actual Price(INR)/Quintal":8750,"Percentage Difference":"-3.55%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":8619,"Actual Price(INR)/Quintal":8577,"Percentage Difference":"-0.49%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":8529,"Actual Price(INR)/Quintal":8213,"Percentage Difference":"-3.85%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":8140,"Actual Price(INR)/Quintal":7919,"Percentage Difference":"-2.79%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":7900,"Actual Price(INR)/Quintal":8164,"Percentage Difference":"3.23%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":7920,"Actual Price(INR)/Quintal":8418,"Percentage Difference":"5.92%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":8754,"Actual Price(INR)/Quintal":9124,"Percentage Difference":"4.06%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":9197,"Actual Price(INR)/Quintal":8933,"Percentage Difference":"-2.96%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":8567,"Actual Price(INR)/Quintal":8656,"Percentage Difference":"1.03%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":8631,"Actual Price(INR)/Quintal":8897,"Percentage Difference":"2.99%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":8848,"Actual Price(INR)/Quintal":9032,"Percentage Difference":"2.04%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":8869,"Actual Price(INR)/Quintal":8878,"Percentage Difference":"0.10%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":8653,"Actual Price(INR)/Quintal":8465,"Percentage Difference":"-2.22%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":8569,"Actual Price(INR)/Quintal":8137,"Percentage Difference":"-5.31%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5937,"Actual Price(INR)/Quintal":6007,"Percentage Difference":"1.17%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6070,"Actual Price(INR)/Quintal":5887,"Percentage Difference":"-3.11%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5953,"Actual Price(INR)/Quintal":6140,"Percentage Difference":"3.05%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":5916,"Actual Price(INR)/Quintal":5800,"Percentage Difference":"-2.00%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":5799,"Actual Price(INR)/Quintal":5650,"Percentage Difference":"-2.64%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":5602,"Actual Price(INR)/Quintal":5668,"Percentage Difference":"1.16%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5598,"Actual Price(INR)/Quintal":5679,"Percentage Difference":"1.43%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5589,"Actual Price(INR)/Quintal":5555,"Percentage Difference":"-0.61%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":5706,"Actual Price(INR)/Quintal":5749,"Percentage Difference":"0.75%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":5711,"Actual Price(INR)/Quintal":5741,"Percentage Difference":"0.52%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":5655,"Actual Price(INR)/Quintal":5750,"Percentage Difference":"1.65%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":5789,"Actual Price(INR)/Quintal":5946,"Percentage Difference":"2.64%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":5718,"Actual Price(INR)/Quintal":5729,"Percentage Difference":"0.19%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":5729,"Actual Price(INR)/Quintal":5550,"Percentage Difference":"-3.23%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":5604,"Actual Price(INR)/Quintal":5525,"Percentage Difference":"-1.43%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":5610,"Actual Price(INR)/Quintal":5681,"Percentage Difference":"1.25%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":5612,"Actual Price(INR)/Quintal":5500,"Percentage Difference":"-2.04%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":5642,"Actual Price(INR)/Quintal":5500,"Percentage Difference":"-2.58%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5635,"Actual Price(INR)/Quintal":5475,"Percentage Difference":"-2.92%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5445,"Actual Price(INR)/Quintal":5345,"Percentage Difference":"-1.87%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5200,"Actual Price(INR)/Quintal":5064,"Percentage Difference":"-2.69%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5196,"Actual Price(INR)/Quintal":5139,"Percentage Difference":"-1.87%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5210,"Actual Price(INR)/Quintal":5400,"Percentage Difference":"3.52%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5388,"Actual Price(INR)/Quintal":5454,"Percentage Difference":"1.21%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":5344,"Actual Price(INR)/Quintal":5500,"Percentage Difference":"2.84%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":5372,"Actual Price(INR)/Quintal":5470,"Percentage Difference":"1.79%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5459,"Actual Price(INR)/Quintal":5480,"Percentage Difference":"0.38%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5424,"Actual Price(INR)/Quintal":5600,"Percentage Difference":"3.14%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":5543,"Actual Price(INR)/Quintal":5691,"Percentage Difference":"2.60%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5453,"Actual Price(INR)/Quintal":5643,"Percentage Difference":"3.37%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5620,"Actual Price(INR)/Quintal":5746,"Percentage Difference":"2.19%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":5667,"Actual Price(INR)/Quintal":5723,"Percentage Difference":"0.98%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":5613,"Actual Price(INR)/Quintal":5880,"Percentage Difference":"4.54%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6846,"Actual Price(INR)/Quintal":7050,"Percentage Difference":"2.89%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6850,"Actual Price(INR)/Quintal":7066,"Percentage Difference":"3.06%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6696,"Actual Price(INR)/Quintal":6450,"Percentage Difference":"-3.81%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6337,"Actual Price(INR)/Quintal":6130,"Percentage Difference":"-3.38%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6155,"Actual Price(INR)/Quintal":6319,"Percentage Difference":"2.60%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":6165,"Actual Price(INR)/Quintal":6230,"Percentage Difference":"1.04%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6111,"Actual Price(INR)/Quintal":6220,"Percentage Difference":"1.75%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6141,"Actual Price(INR)/Quintal":6240,"Percentage Difference":"1.59%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6133,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"-0.54%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6123,"Actual Price(INR)/Quintal":6275,"Percentage Difference":"2.42%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":6146,"Actual Price(INR)/Quintal":6112,"Percentage Difference":"-0.56%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6167,"Actual Price(INR)/Quintal":6168,"Percentage Difference":"0.02%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6249,"Actual Price(INR)/Quintal":6180,"Percentage Difference":"-1.12%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6121,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"1.27%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6177,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"-1.26%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":5982,"Actual Price(INR)/Quintal":5929,"Percentage Difference":"-0.89%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":5919,"Actual Price(INR)/Quintal":6009,"Percentage Difference":"1.50%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":5889,"Actual Price(INR)/Quintal":5960,"Percentage Difference":"1.19%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5988,"Actual Price(INR)/Quintal":6001,"Percentage Difference":"0.22%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5929,"Actual Price(INR)/Quintal":5881,"Percentage Difference":"-0.82%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":5978,"Actual Price(INR)/Quintal":6166,"Percentage Difference":"3.05%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":5924,"Actual Price(INR)/Quintal":6130,"Percentage Difference":"3.36%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":6037,"Actual Price(INR)/Quintal":6116,"Percentage Difference":"1.29%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":6099,"Actual Price(INR)/Quintal":6142,"Percentage Difference":"0.70%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6092,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"1.74%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6064,"Actual Price(INR)/Quintal":5890,"Percentage Difference":"-2.95%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6066,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"0.56%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":6061,"Actual Price(INR)/Quintal":5868,"Percentage Difference":"-3.29%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":5836,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"1.08%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":5853,"Actual Price(INR)/Quintal":5861,"Percentage Difference":"0.14%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":5839,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"1.03%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":5990,"Actual Price(INR)/Quintal":5812,"Percentage Difference":"-3.06%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":5933,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"2.74%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5913,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"1.45%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5930,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"1.17%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5901,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"1.65%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5811,"Actual Price(INR)/Quintal":5781,"Percentage Difference":"-0.52%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5816,"Actual Price(INR)/Quintal":6009,"Percentage Difference":"3.21%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5966,"Actual Price(INR)/Quintal":6006,"Percentage Difference":"0.67%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":5952,"Actual Price(INR)/Quintal":6001,"Percentage Difference":"0.82%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":5845,"Actual Price(INR)/Quintal":5803,"Percentage Difference":"-0.72%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5835,"Actual Price(INR)/Quintal":5700,"Percentage Difference":"-2.37%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5724,"Actual Price(INR)/Quintal":5800,"Percentage Difference":"1.31%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":5720,"Actual Price(INR)/Quintal":5777,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5832,"Actual Price(INR)/Quintal":5830,"Percentage Difference":"-0.03%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5974,"Actual Price(INR)/Quintal":5988,"Percentage Difference":"0.23%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6142,"Actual Price(INR)/Quintal":6300,"Percentage Difference":"2.51%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6602,"Actual Price(INR)/Quintal":6633,"Percentage Difference":"0.47%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6705,"Actual Price(INR)/Quintal":6700,"Percentage Difference":"-0.07%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6094,"Actual Price(INR)/Quintal":6125,"Percentage Difference":"0.51%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":5806,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"1.59%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6061,"Actual Price(INR)/Quintal":6125,"Percentage Difference":"1.04%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":5881,"Actual Price(INR)/Quintal":5821,"Percentage Difference":"-1.03%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":5820,"Actual Price(INR)/Quintal":5884,"Percentage Difference":"1.09%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":5846,"Actual Price(INR)/Quintal":5905,"Percentage Difference":"1.00%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":5883,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"1.95%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":5902,"Actual Price(INR)/Quintal":5980,"Percentage Difference":"1.30%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":5897,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"0.05%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5881,"Actual Price(INR)/Quintal":6030,"Percentage Difference":"2.47%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5924,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"2.89%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6006,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"3.13%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6099,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"0.02%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6037,"Actual Price(INR)/Quintal":5853,"Percentage Difference":"-3.14%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":6106,"Actual Price(INR)/Quintal":6282,"Percentage Difference":"2.80%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":6074,"Actual Price(INR)/Quintal":6210,"Percentage Difference":"2.19%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":6114,"Actual Price(INR)/Quintal":6429,"Percentage Difference":"4.90%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":6204,"Actual Price(INR)/Quintal":6400,"Percentage Difference":"3.06%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5900,"Actual Price(INR)/Quintal":6350,"Percentage Difference":"7.09%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":5863,"Actual Price(INR)/Quintal":5597,"Percentage Difference":"-4.75%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":5570,"Actual Price(INR)/Quintal":5470,"Percentage Difference":"-1.83%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":5044,"Actual Price(INR)/Quintal":5236,"Percentage Difference":"3.67%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":5082,"Actual Price(INR)/Quintal":5181,"Percentage Difference":"1.91%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":5051,"Actual Price(INR)/Quintal":5581,"Percentage Difference":"9.50%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":5770,"Actual Price(INR)/Quintal":6133,"Percentage Difference":"5.92%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6177,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":6162,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"0.61%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":6237,"Actual Price(INR)/Quintal":6300,"Percentage Difference":"1.00%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":6201,"Actual Price(INR)/Quintal":6400,"Percentage Difference":"3.11%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":6447,"Actual Price(INR)/Quintal":6600,"Percentage Difference":"2.32%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":6579,"Actual Price(INR)/Quintal":6776,"Percentage Difference":"2.91%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":6580,"Actual Price(INR)/Quintal":6779,"Percentage Difference":"2.94%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":6654,"Actual Price(INR)/Quintal":6500,"Percentage Difference":"-2.37%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":6555,"Actual Price(INR)/Quintal":6251,"Percentage Difference":"-4.86%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":6131,"Actual Price(INR)/Quintal":6253,"Percentage Difference":"1.95%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":6039,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"-0.65%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":6054,"Actual Price(INR)/Quintal":6106,"Percentage Difference":"0.85%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":6012,"Actual Price(INR)/Quintal":6316,"Percentage Difference":"4.81%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":6243,"Actual Price(INR)/Quintal":6138,"Percentage Difference":"-1.71%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":6147,"Actual Price(INR)/Quintal":5102,"Percentage Difference":"-20.48%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":5151,"Actual Price(INR)/Quintal":5250,"Percentage Difference":"1.89%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":5092,"Actual Price(INR)/Quintal":5353,"Percentage Difference":"4.88%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":5336,"Actual Price(INR)/Quintal":5250,"Percentage Difference":"-1.64%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":5257,"Actual Price(INR)/Quintal":5076,"Percentage Difference":"-3.57%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":5089,"Actual Price(INR)/Quintal":5250,"Percentage Difference":"3.07%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":5073,"Actual Price(INR)/Quintal":5150,"Percentage Difference":"1.50%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":5067,"Actual Price(INR)/Quintal":5085,"Percentage Difference":"0.35%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":5284,"Actual Price(INR)/Quintal":5405,"Percentage Difference":"2.24%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":5506,"Actual Price(INR)/Quintal":5699,"Percentage Difference":"3.39%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":6088,"Actual Price(INR)/Quintal":6274,"Percentage Difference":"2.96%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":6246,"Actual Price(INR)/Quintal":6212,"Percentage Difference":"-0.55%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":6299,"Actual Price(INR)/Quintal":6264,"Percentage Difference":"-0.56%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":6304,"Actual Price(INR)/Quintal":6248,"Percentage Difference":"-0.90%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":6222,"Actual Price(INR)/Quintal":6173,"Percentage Difference":"-0.79%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":6211,"Actual Price(INR)/Quintal":6258,"Percentage Difference":"0.75%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":6278,"Actual Price(INR)/Quintal":6322,"Percentage Difference":"0.70%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":6235,"Actual Price(INR)/Quintal":6122,"Percentage Difference":"-1.85%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":6201,"Actual Price(INR)/Quintal":6203,"Percentage Difference":"0.03%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":6248,"Actual Price(INR)/Quintal":6399,"Percentage Difference":"2.36%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":6213,"Actual Price(INR)/Quintal":6396,"Percentage Difference":"2.86%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":6151,"Actual Price(INR)/Quintal":6084,"Percentage Difference":"-1.10%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":6050,"Actual Price(INR)/Quintal":5959,"Percentage Difference":"-1.53%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 4711,
    "Actual Price(INR)/Quintal": 5049,
    "Percentage Difference": "6.69%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 5185,
    "Actual Price(INR)/Quintal": 4947,
    "Percentage Difference": "-4.81%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 4866,
    "Actual Price(INR)/Quintal": 5149,
    "Percentage Difference": "5.50%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 5017,
    "Actual Price(INR)/Quintal": 5211,
    "Percentage Difference": "3.72%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 5127,
    "Actual Price(INR)/Quintal": 5689,
    "Percentage Difference": "9.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 5505,
    "Actual Price(INR)/Quintal": 5844,
    "Percentage Difference": "5.80%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 5872,
    "Actual Price(INR)/Quintal": 5656,
    "Percentage Difference": "-3.82%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 5617,
    "Actual Price(INR)/Quintal": 5320,
    "Percentage Difference": "-5.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 5332,
    "Actual Price(INR)/Quintal": 5378,
    "Percentage Difference": "0.86%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 5325,
    "Actual Price(INR)/Quintal": 5608,
    "Percentage Difference": "5.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 5666,
    "Actual Price(INR)/Quintal": 6061,
    "Percentage Difference": "6.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 5905,
    "Actual Price(INR)/Quintal": 6202,
    "Percentage Difference": "4.79%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 6160,
    "Actual Price(INR)/Quintal": 5881,
    "Percentage Difference": "-4.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 5736,
    "Actual Price(INR)/Quintal": 5546,
    "Percentage Difference": "-3.43%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 5499,
    "Actual Price(INR)/Quintal": 5521,
    "Percentage Difference": "0.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 5524,
    "Actual Price(INR)/Quintal": 5532,
    "Percentage Difference": "0.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 5553,
    "Actual Price(INR)/Quintal": 5675,
    "Percentage Difference": "2.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 5587,
    "Actual Price(INR)/Quintal": 5649,
    "Percentage Difference": "1.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 5609,
    "Actual Price(INR)/Quintal": 5620,
    "Percentage Difference": "0.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 5588,
    "Actual Price(INR)/Quintal": 5806,
    "Percentage Difference": "3.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 5600,
    "Actual Price(INR)/Quintal": 5779,
    "Percentage Difference": "3.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 5695,
    "Actual Price(INR)/Quintal": 5514,
    "Percentage Difference": "-3.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 5555,
    "Actual Price(INR)/Quintal": 5786,
    "Percentage Difference": "3.99%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 5666,
    "Actual Price(INR)/Quintal": 5676,
    "Percentage Difference": "0.18%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 5697,
    "Actual Price(INR)/Quintal": 5446,
    "Percentage Difference": "-4.61%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 5390,
    "Actual Price(INR)/Quintal": 5399,
    "Percentage Difference": "0.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 5396,
    "Actual Price(INR)/Quintal": 5008,
    "Percentage Difference": "-7.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 4894,
    "Actual Price(INR)/Quintal": 5008,
    "Percentage Difference": "2.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 4895,
    "Actual Price(INR)/Quintal": 4907,
    "Percentage Difference": "0.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 4897,
    "Actual Price(INR)/Quintal": 4951,
    "Percentage Difference": "1.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 4898,
    "Actual Price(INR)/Quintal": 4802,
    "Percentage Difference": "-2.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 4700,
    "Actual Price(INR)/Quintal": 4800,
    "Percentage Difference": "2.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 4839,
    "Actual Price(INR)/Quintal": 4902,
    "Percentage Difference": "1.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 4832,
    "Actual Price(INR)/Quintal": 5132,
    "Percentage Difference": "5.85%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 5067,
    "Actual Price(INR)/Quintal": 5310,
    "Percentage Difference": "4.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 5492,
    "Actual Price(INR)/Quintal": 5325,
    "Percentage Difference": "-3.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 5338,
    "Actual Price(INR)/Quintal": 5250,
    "Percentage Difference": "-1.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 5153,
    "Actual Price(INR)/Quintal": 5376,
    "Percentage Difference": "4.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 5122,
    "Actual Price(INR)/Quintal": 5306,
    "Percentage Difference": "3.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 5403,
    "Actual Price(INR)/Quintal": 5500,
    "Percentage Difference": "1.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 6347,
    "Actual Price(INR)/Quintal": 6500,
    "Percentage Difference": "2.35%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 6399,
    "Actual Price(INR)/Quintal": 6455,
    "Percentage Difference": "0.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 5873,
    "Actual Price(INR)/Quintal": 6384,
    "Percentage Difference": "8.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 5866,
    "Actual Price(INR)/Quintal": 6077,
    "Percentage Difference": "3.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 5452,
    "Actual Price(INR)/Quintal": 5501,
    "Percentage Difference": "0.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 5896,
    "Actual Price(INR)/Quintal": 6048,
    "Percentage Difference": "2.51%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 5649,
    "Actual Price(INR)/Quintal": 5770,
    "Percentage Difference": "2.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 5958,
    "Actual Price(INR)/Quintal": 5905,
    "Percentage Difference": "-0.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 4485,
    "Actual Price(INR)/Quintal": 4281,
    "Percentage Difference": "-4.77%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 4259,
    "Actual Price(INR)/Quintal": 3959,
    "Percentage Difference": "-7.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 3770,
    "Actual Price(INR)/Quintal": 3938,
    "Percentage Difference": "4.27%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 3972,
    "Actual Price(INR)/Quintal": 3990,
    "Percentage Difference": "0.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 3912,
    "Actual Price(INR)/Quintal": 4037,
    "Percentage Difference": "3.10%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 3966,
    "Actual Price(INR)/Quintal": 4148,
    "Percentage Difference": "4.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 4252,
    "Actual Price(INR)/Quintal": 4292,
    "Percentage Difference": "0.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 4269,
    "Actual Price(INR)/Quintal": 3927,
    "Percentage Difference": "-8.71%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 3934,
    "Actual Price(INR)/Quintal": 3938,
    "Percentage Difference": "0.10%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 4035,
    "Actual Price(INR)/Quintal": 3971,
    "Percentage Difference": "-1.61%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 3782,
    "Actual Price(INR)/Quintal": 4002,
    "Percentage Difference": "5.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 3965,
    "Actual Price(INR)/Quintal": 4032,
    "Percentage Difference": "1.66%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 4266,
    "Actual Price(INR)/Quintal": 4485,
    "Percentage Difference": "4.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 4326,
    "Actual Price(INR)/Quintal": 4171,
    "Percentage Difference": "-3.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 4077,
    "Actual Price(INR)/Quintal": 4511,
    "Percentage Difference": "9.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 4716,
    "Actual Price(INR)/Quintal": 4559,
    "Percentage Difference": "-3.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 4577,
    "Actual Price(INR)/Quintal": 4354,
    "Percentage Difference": "-5.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 4327,
    "Actual Price(INR)/Quintal": 4629,
    "Percentage Difference": "6.52%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 4661,
    "Actual Price(INR)/Quintal": 4669,
    "Percentage Difference": "0.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 4653,
    "Actual Price(INR)/Quintal": 4538,
    "Percentage Difference": "-2.53%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 4470,
    "Actual Price(INR)/Quintal": 4542,
    "Percentage Difference": "1.59%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 4474,
    "Actual Price(INR)/Quintal": 4195,
    "Percentage Difference": "-6.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 4081,
    "Actual Price(INR)/Quintal": 3887,
    "Percentage Difference": "-4.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 3875,
    "Actual Price(INR)/Quintal": 3732,
    "Percentage Difference": "-3.83%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 3765,
    "Actual Price(INR)/Quintal": 3598,
    "Percentage Difference": "-4.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 3646,
    "Actual Price(INR)/Quintal": 3822,
    "Percentage Difference": "4.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 3757,
    "Actual Price(INR)/Quintal": 4025,
    "Percentage Difference": "6.66%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 4069,
    "Actual Price(INR)/Quintal": 4138,
    "Percentage Difference": "1.67%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 4093,
    "Actual Price(INR)/Quintal": 4317,
    "Percentage Difference": "5.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 4299,
    "Actual Price(INR)/Quintal": 4619,
    "Percentage Difference": "6.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 4486,
    "Actual Price(INR)/Quintal": 4541,
    "Percentage Difference": "1.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 5003,
    "Actual Price(INR)/Quintal": 4549,
    "Percentage Difference": "-9.98%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 4294,
    "Actual Price(INR)/Quintal": 4398,
    "Percentage Difference": "2.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 4335,
    "Actual Price(INR)/Quintal": 4176,
    "Percentage Difference": "-3.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 4141,
    "Actual Price(INR)/Quintal": 3909,
    "Percentage Difference": "-5.94%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 3914,
    "Actual Price(INR)/Quintal": 3931,
    "Percentage Difference": "0.43%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 3954,
    "Actual Price(INR)/Quintal": 4093,
    "Percentage Difference": "3.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 3981,
    "Actual Price(INR)/Quintal": 4005,
    "Percentage Difference": "0.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 3957,
    "Actual Price(INR)/Quintal": 4107,
    "Percentage Difference": "3.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 4110,
    "Actual Price(INR)/Quintal": 4445,
    "Percentage Difference": "7.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 4603,
    "Actual Price(INR)/Quintal": 4838,
    "Percentage Difference": "4.86%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 4827,
    "Actual Price(INR)/Quintal": 5116,
    "Percentage Difference": "5.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 5158,
    "Actual Price(INR)/Quintal": 5090,
    "Percentage Difference": "-1.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 5044,
    "Actual Price(INR)/Quintal": 5068,
    "Percentage Difference": "0.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 5062,
    "Actual Price(INR)/Quintal": 4887,
    "Percentage Difference": "-3.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 4854,
    "Actual Price(INR)/Quintal": 4633,
    "Percentage Difference": "-4.77%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 4690,
    "Actual Price(INR)/Quintal": 4701,
    "Percentage Difference": "0.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 4676,
    "Actual Price(INR)/Quintal": 4709,
    "Percentage Difference": "0.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 6870,
    "Actual Price(INR)/Quintal": 6552,
    "Percentage Difference": "-4.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 5547,
    "Actual Price(INR)/Quintal": 5535,
    "Percentage Difference": "-0.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 5632,
    "Actual Price(INR)/Quintal": 5860,
    "Percentage Difference": "3.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 5530,
    "Actual Price(INR)/Quintal": 5628,
    "Percentage Difference": "1.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 5532,
    "Actual Price(INR)/Quintal": 5725,
    "Percentage Difference": "3.37%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 5522,
    "Actual Price(INR)/Quintal": 5657,
    "Percentage Difference": "2.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 5655,
    "Actual Price(INR)/Quintal": 6025,
    "Percentage Difference": "6.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 5821,
    "Actual Price(INR)/Quintal": 6196,
    "Percentage Difference": "6.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 5804,
    "Actual Price(INR)/Quintal": 5874,
    "Percentage Difference": "1.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 5881,
    "Actual Price(INR)/Quintal": 5754,
    "Percentage Difference": "-2.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 6316,
    "Actual Price(INR)/Quintal": 6424,
    "Percentage Difference": "1.68%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 6320,
    "Actual Price(INR)/Quintal": 6583,
    "Percentage Difference": "4.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 6730,
    "Actual Price(INR)/Quintal": 6838,
    "Percentage Difference": "1.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 6304,
    "Actual Price(INR)/Quintal": 6320,
    "Percentage Difference": "0.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 6096,
    "Actual Price(INR)/Quintal": 6300,
    "Percentage Difference": "3.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 5993,
    "Actual Price(INR)/Quintal": 6178,
    "Percentage Difference": "2.99%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 6084,
    "Actual Price(INR)/Quintal": 6112,
    "Percentage Difference": "0.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 6093,
    "Actual Price(INR)/Quintal": 5848,
    "Percentage Difference": "-4.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 6360,
    "Actual Price(INR)/Quintal": 6525,
    "Percentage Difference": "2.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 6068,
    "Actual Price(INR)/Quintal": 5824,
    "Percentage Difference": "-4.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 6395,
    "Actual Price(INR)/Quintal": 6192,
    "Percentage Difference": "-3.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 6318,
    "Actual Price(INR)/Quintal": 6226,
    "Percentage Difference": "-1.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 6733,
    "Actual Price(INR)/Quintal": 6833,
    "Percentage Difference": "1.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 6327,
    "Actual Price(INR)/Quintal": 6755,
    "Percentage Difference": "6.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 5703,
    "Actual Price(INR)/Quintal": 5439,
    "Percentage Difference": "-4.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 6178,
    "Actual Price(INR)/Quintal": 6230,
    "Percentage Difference": "0.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 6184,
    "Actual Price(INR)/Quintal": 5995,
    "Percentage Difference": "-3.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 5880,
    "Actual Price(INR)/Quintal": 5655,
    "Percentage Difference": "-3.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 5900,
    "Actual Price(INR)/Quintal": 5774,
    "Percentage Difference": "-2.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 5600,
    "Actual Price(INR)/Quintal": 5602,
    "Percentage Difference": "0.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 6120,
    "Actual Price(INR)/Quintal": 6378,
    "Percentage Difference": "4.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 6130,
    "Actual Price(INR)/Quintal": 5809,
    "Percentage Difference": "-5.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 5821,
    "Actual Price(INR)/Quintal": 5618,
    "Percentage Difference": "-3.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 4900,
    "Actual Price(INR)/Quintal": 4577,
    "Percentage Difference": "-7.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 4899,
    "Actual Price(INR)/Quintal": 4585,
    "Percentage Difference": "-6.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 4516,
    "Actual Price(INR)/Quintal": 4288,
    "Percentage Difference": "-5.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 4168,
    "Actual Price(INR)/Quintal": 4174,
    "Percentage Difference": "0.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 4168,
    "Actual Price(INR)/Quintal": 4174,
    "Percentage Difference": "0.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 4136,
    "Actual Price(INR)/Quintal": 3921,
    "Percentage Difference": "-5.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 3946,
    "Actual Price(INR)/Quintal": 4132,
    "Percentage Difference": "4.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 4214,
    "Actual Price(INR)/Quintal": 4048,
    "Percentage Difference": "-4.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 4089,
    "Actual Price(INR)/Quintal": 3895,
    "Percentage Difference": "-4.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 3863,
    "Actual Price(INR)/Quintal": 3925,
    "Percentage Difference": "1.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 3887,
    "Actual Price(INR)/Quintal": 3846,
    "Percentage Difference": "-1.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 3827,
    "Actual Price(INR)/Quintal": 3815,
    "Percentage Difference": "-0.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 3858,
    "Actual Price(INR)/Quintal": 4038,
    "Percentage Difference": "4.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 3990,
    "Actual Price(INR)/Quintal": 4057,
    "Percentage Difference": "1.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 3921,
    "Actual Price(INR)/Quintal": 4062,
    "Percentage Difference": "3.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 4066,
    "Actual Price(INR)/Quintal": 4360,
    "Percentage Difference": "6.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 8428,
    "Actual Price(INR)/Quintal": 8417,
    "Percentage Difference": "-0.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 8639,
    "Actual Price(INR)/Quintal": 8548,
    "Percentage Difference": "-1.06%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 8420,
    "Actual Price(INR)/Quintal": 8881,
    "Percentage Difference": "5.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 7815,
    "Actual Price(INR)/Quintal": 7980,
    "Percentage Difference": "2.07%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 8185,
    "Actual Price(INR)/Quintal": 7857,
    "Percentage Difference": "-4.17%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 8035,
    "Actual Price(INR)/Quintal": 8383,
    "Percentage Difference": "4.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 8056,
    "Actual Price(INR)/Quintal": 8162,
    "Percentage Difference": "1.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 9030,
    "Actual Price(INR)/Quintal": 8612,
    "Percentage Difference": "-4.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 8568,
    "Actual Price(INR)/Quintal": 8413,
    "Percentage Difference": "-1.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 8551,
    "Actual Price(INR)/Quintal": 7969,
    "Percentage Difference": "-7.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 8047,
    "Actual Price(INR)/Quintal": 8228,
    "Percentage Difference": "2.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 8141,
    "Actual Price(INR)/Quintal": 7842,
    "Percentage Difference": "-3.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 7917,
    "Actual Price(INR)/Quintal": 8352,
    "Percentage Difference": "5.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 8534,
    "Actual Price(INR)/Quintal": 8761,
    "Percentage Difference": "2.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 8513,
    "Actual Price(INR)/Quintal": 10758,
    "Percentage Difference": "20.86%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 10471,
    "Actual Price(INR)/Quintal": 9362,
    "Percentage Difference": "-11.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 9312,
    "Actual Price(INR)/Quintal": 10858,
    "Percentage Difference": "14.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 10543,
    "Actual Price(INR)/Quintal": 9542,
    "Percentage Difference": "-10.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 9645,
    "Actual Price(INR)/Quintal": 9033,
    "Percentage Difference": "-6.78%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 9016,
    "Actual Price(INR)/Quintal": 8550,
    "Percentage Difference": "-5.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 8653,
    "Actual Price(INR)/Quintal": 8663,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 8583,
    "Actual Price(INR)/Quintal": 9181,
    "Percentage Difference": "6.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 9503,
    "Actual Price(INR)/Quintal": 8838,
    "Percentage Difference": "-7.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 9190,
    "Actual Price(INR)/Quintal": 8802,
    "Percentage Difference": "-4.41%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 8849,
    "Actual Price(INR)/Quintal": 8869,
    "Percentage Difference": "0.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 8816,
    "Actual Price(INR)/Quintal": 8549,
    "Percentage Difference": "-3.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 8560,
    "Actual Price(INR)/Quintal": 8602,
    "Percentage Difference": "0.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 8665,
    "Actual Price(INR)/Quintal": 8511,
    "Percentage Difference": "-1.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 8503,
    "Actual Price(INR)/Quintal": 8542,
    "Percentage Difference": "0.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 8223,
    "Actual Price(INR)/Quintal": 8482,
    "Percentage Difference": "3.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 8327,
    "Actual Price(INR)/Quintal": 8330,
    "Percentage Difference": "0.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 7216,
    "Actual Price(INR)/Quintal": 6892,
    "Percentage Difference": "-4.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 7006,
    "Actual Price(INR)/Quintal": 7174,
    "Percentage Difference": "2.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 7018,
    "Actual Price(INR)/Quintal": 6806,
    "Percentage Difference": "-3.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 6811,
    "Actual Price(INR)/Quintal": 7034,
    "Percentage Difference": "3.17%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 6912,
    "Actual Price(INR)/Quintal": 7309,
    "Percentage Difference": "5.43%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 7137,
    "Actual Price(INR)/Quintal": 7619,
    "Percentage Difference": "6.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 7652,
    "Actual Price(INR)/Quintal": 7904,
    "Percentage Difference": "3.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 7666,
    "Actual Price(INR)/Quintal": 7028,
    "Percentage Difference": "-9.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 7286,
    "Actual Price(INR)/Quintal": 7316,
    "Percentage Difference": "0.41%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 7276,
    "Actual Price(INR)/Quintal": 6825,
    "Percentage Difference": "-6.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 6848,
    "Actual Price(INR)/Quintal": 7525,
    "Percentage Difference": "8.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 7239,
    "Actual Price(INR)/Quintal": 6954,
    "Percentage Difference": "-4.10%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 7143,
    "Actual Price(INR)/Quintal": 6736,
    "Percentage Difference": "-6.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 7105,
    "Actual Price(INR)/Quintal": 6641,
    "Percentage Difference": "-6.87%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 6635,
    "Actual Price(INR)/Quintal": 6849,
    "Percentage Difference": "3.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 6813,
    "Actual Price(INR)/Quintal": 6443,
    "Percentage Difference": "-5.43%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 6524,
    "Actual Price(INR)/Quintal": 6645,
    "Percentage Difference": "1.82%",
    "YEAR": 2016
  }
];
