export const Bajra = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2536,"Actual Price(INR)/Quintal":2503,"Percentage Difference":"-1.32%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2520,"Actual Price(INR)/Quintal":2536,"Percentage Difference":"0.63%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2469,"Actual Price(INR)/Quintal":2355,"Percentage Difference":"-4.84%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2324,"Actual Price(INR)/Quintal":2461,"Percentage Difference":"5.57%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2423,"Actual Price(INR)/Quintal":2503,"Percentage Difference":"3.20%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2418,"Actual Price(INR)/Quintal":2492,"Percentage Difference":"2.97%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2433,"Actual Price(INR)/Quintal":2499,"Percentage Difference":"2.64%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2428,"Actual Price(INR)/Quintal":2450,"Percentage Difference":"0.90%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2485,"Actual Price(INR)/Quintal":2489,"Percentage Difference":"0.16%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2494,"Actual Price(INR)/Quintal":2509,"Percentage Difference":"0.60%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2504,"Actual Price(INR)/Quintal":2469,"Percentage Difference":"-1.42%","YEAR":2024},
{ "Year : Month Week#":"Markwk4","Predicted Price(INR)/Quintal":2426,"Actual Price(INR)/Quintal":2449,"Percentage Difference":"0.94%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2436,"Actual Price(INR)/Quintal":2310,"Percentage Difference":"-5.45%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2429,"Actual Price(INR)/Quintal":2360,"Percentage Difference":"-2.92%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2426,"Actual Price(INR)/Quintal":2392,"Percentage Difference":"-1.42%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2364,"Actual Price(INR)/Quintal":2430,"Percentage Difference":"2.72%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2295,"Actual Price(INR)/Quintal":2350,"Percentage Difference":"2.34%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2342,"Actual Price(INR)/Quintal":2416,"Percentage Difference":"3.06%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2408,"Actual Price(INR)/Quintal":2456,"Percentage Difference":"1.95%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2404,"Actual Price(INR)/Quintal":2399,"Percentage Difference":"-0.21%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2436,"Actual Price(INR)/Quintal":2443,"Percentage Difference":"0.29%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2429,"Actual Price(INR)/Quintal":2407,"Percentage Difference":"-0.91%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2430,"Actual Price(INR)/Quintal":2440,"Percentage Difference":"0.41%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2438,"Actual Price(INR)/Quintal":2465,"Percentage Difference":"1.10%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2462,"Actual Price(INR)/Quintal":2458,"Percentage Difference":"-0.16%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2435,"Actual Price(INR)/Quintal":2536,"Percentage Difference":"3.98%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2401,"Actual Price(INR)/Quintal":2538,"Percentage Difference":"5.40%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2498,"Actual Price(INR)/Quintal":2568,"Percentage Difference":"2.73%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2483,"Actual Price(INR)/Quintal":2555,"Percentage Difference":"2.82%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2491,"Actual Price(INR)/Quintal":2530,"Percentage Difference":"1.54%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2468,"Actual Price(INR)/Quintal":2535,"Percentage Difference":"2.64%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2450,"Actual Price(INR)/Quintal":2520,"Percentage Difference":"2.78%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2428,"Actual Price(INR)/Quintal":2515,"Percentage Difference":"3.46%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2436,"Actual Price(INR)/Quintal":2518,"Percentage Difference":"3.26%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2397,"Actual Price(INR)/Quintal":2306,"Percentage Difference":"-3.95%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2215,"Actual Price(INR)/Quintal":2192,"Percentage Difference":"-1.05%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2270,"Actual Price(INR)/Quintal":2223,"Percentage Difference":"-2.11%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2246,"Actual Price(INR)/Quintal":2248,"Percentage Difference":"0.09%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2228,"Actual Price(INR)/Quintal":2262,"Percentage Difference":"1.50%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2140,"Actual Price(INR)/Quintal":2242,"Percentage Difference":"4.55%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2268,"Actual Price(INR)/Quintal":2351,"Percentage Difference":"3.53%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2370,"Actual Price(INR)/Quintal":2322,"Percentage Difference":"-2.07%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2291,"Actual Price(INR)/Quintal":2301,"Percentage Difference":"0.43%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2274,"Actual Price(INR)/Quintal":2335,"Percentage Difference":"2.61%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2265,"Actual Price(INR)/Quintal":2274,"Percentage Difference":"0.40%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2310,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2313,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2317,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2160,"Actual Price(INR)/Quintal":2209,"Percentage Difference":"2.22%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2190,"Percentage Difference":"3.20%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2075,"Actual Price(INR)/Quintal":2080,"Percentage Difference":"0.24%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2260,"Actual Price(INR)/Quintal":2269,"Percentage Difference":"0.40%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2303,"Actual Price(INR)/Quintal":2357,"Percentage Difference":"2.29%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2312,"Actual Price(INR)/Quintal":2271,"Percentage Difference":"-1.81%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2243,"Actual Price(INR)/Quintal":2275,"Percentage Difference":"1.41%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2263,"Actual Price(INR)/Quintal":2356,"Percentage Difference":"3.95%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2338,"Actual Price(INR)/Quintal":2465,"Percentage Difference":"5.15%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2478,"Actual Price(INR)/Quintal":2572,"Percentage Difference":"3.65%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2519,"Actual Price(INR)/Quintal":2554,"Percentage Difference":"1.37%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2537,"Actual Price(INR)/Quintal":2521,"Percentage Difference":"-0.63%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2484,"Actual Price(INR)/Quintal":2518,"Percentage Difference":"1.35%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2458,"Actual Price(INR)/Quintal":2532,"Percentage Difference":"2.92%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2417,"Actual Price(INR)/Quintal":2316,"Percentage Difference":"-4.36%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2391,"Actual Price(INR)/Quintal":2380,"Percentage Difference":"-0.46%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2367,"Actual Price(INR)/Quintal":2333,"Percentage Difference":"-1.46%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2329,"Actual Price(INR)/Quintal":2332,"Percentage Difference":"0.13%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2330,"Actual Price(INR)/Quintal":2362,"Percentage Difference":"1.35%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2380,"Actual Price(INR)/Quintal":2401,"Percentage Difference":"0.87%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2309,"Actual Price(INR)/Quintal":2410,"Percentage Difference":"4.19%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2371,"Actual Price(INR)/Quintal":2442,"Percentage Difference":"2.91%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2334,"Actual Price(INR)/Quintal":2340,"Percentage Difference":"0.26%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2372,"Actual Price(INR)/Quintal":2414,"Percentage Difference":"1.74%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2402,"Actual Price(INR)/Quintal":2466,"Percentage Difference":"2.60%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2421,"Actual Price(INR)/Quintal":2539,"Percentage Difference":"4.65%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2462,"Actual Price(INR)/Quintal":2581,"Percentage Difference":"4.61%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2540,"Actual Price(INR)/Quintal":2537,"Percentage Difference":"-0.12%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2591,"Actual Price(INR)/Quintal":2653,"Percentage Difference":"2.34%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2602,"Actual Price(INR)/Quintal":2615,"Percentage Difference":"0.50%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2646,"Actual Price(INR)/Quintal":2584,"Percentage Difference":"-2.40%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2554,"Actual Price(INR)/Quintal":2593,"Percentage Difference":"1.50%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2619,"Actual Price(INR)/Quintal":2584,"Percentage Difference":"-1.35%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2520,"Actual Price(INR)/Quintal":2390,"Percentage Difference":"-5.44%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2405,"Actual Price(INR)/Quintal":2282,"Percentage Difference":"-5.39%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2220,"Actual Price(INR)/Quintal":2231,"Percentage Difference":"0.49%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2236,"Actual Price(INR)/Quintal":2207,"Percentage Difference":"-1.31%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2153,"Actual Price(INR)/Quintal":2203,"Percentage Difference":"2.27%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2278,"Percentage Difference":"5.14%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2190,"Actual Price(INR)/Quintal":2313,"Percentage Difference":"5.32%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2264,"Actual Price(INR)/Quintal":2381,"Percentage Difference":"4.91%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2469,"Actual Price(INR)/Quintal":2471,"Percentage Difference":"0.08%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2433,"Actual Price(INR)/Quintal":2555,"Percentage Difference":"4.77%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2495,"Actual Price(INR)/Quintal":2610,"Percentage Difference":"4.41%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2468,"Actual Price(INR)/Quintal":2548,"Percentage Difference":"3.14%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2519,"Actual Price(INR)/Quintal":2493,"Percentage Difference":"-1.04%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2573,"Actual Price(INR)/Quintal":2496,"Percentage Difference":"-3.08%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2480,"Actual Price(INR)/Quintal":2519,"Percentage Difference":"1.55%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1601,"Actual Price(INR)/Quintal":1650,"Percentage Difference":"2.97%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1607,"Actual Price(INR)/Quintal":1658,"Percentage Difference":"3.08%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1620,"Actual Price(INR)/Quintal":1645,"Percentage Difference":"1.52%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1662,"Actual Price(INR)/Quintal":1728,"Percentage Difference":"3.82%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1766,"Actual Price(INR)/Quintal":1802,"Percentage Difference":"2.00%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1888,"Actual Price(INR)/Quintal":1891,"Percentage Difference":"0.16%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1802,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"0.33%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1803,"Actual Price(INR)/Quintal":1832,"Percentage Difference":"1.58%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":1941,"Percentage Difference":"1.65%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1951,"Actual Price(INR)/Quintal":2009,"Percentage Difference":"2.89%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2008,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"2.05%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2058,"Actual Price(INR)/Quintal":2139,"Percentage Difference":"3.79%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2118,"Actual Price(INR)/Quintal":2181,"Percentage Difference":"2.89%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2216,"Percentage Difference":"2.48%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2133,"Actual Price(INR)/Quintal":2213,"Percentage Difference":"3.62%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2153,"Actual Price(INR)/Quintal":2178,"Percentage Difference":"1.15%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2156,"Actual Price(INR)/Quintal":2190,"Percentage Difference":"1.55%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2238,"Actual Price(INR)/Quintal":2157,"Percentage Difference":"-3.76%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2108,"Actual Price(INR)/Quintal":2117,"Percentage Difference":"0.43%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2102,"Actual Price(INR)/Quintal":2138,"Percentage Difference":"1.68%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2202,"Percentage Difference":"3.72%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2137,"Actual Price(INR)/Quintal":2193,"Percentage Difference":"2.55%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2185,"Actual Price(INR)/Quintal":2302,"Percentage Difference":"5.08%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2371,"Actual Price(INR)/Quintal":2435,"Percentage Difference":"2.63%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2338,"Actual Price(INR)/Quintal":2340,"Percentage Difference":"0.09%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2309,"Actual Price(INR)/Quintal":2339,"Percentage Difference":"1.28%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2317,"Actual Price(INR)/Quintal":2401,"Percentage Difference":"3.50%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2387,"Actual Price(INR)/Quintal":2393,"Percentage Difference":"0.25%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2367,"Actual Price(INR)/Quintal":2430,"Percentage Difference":"2.59%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2348,"Actual Price(INR)/Quintal":2393,"Percentage Difference":"1.88%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2342,"Actual Price(INR)/Quintal":2394,"Percentage Difference":"2.17%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2329,"Actual Price(INR)/Quintal":2359,"Percentage Difference":"1.27%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2268,"Actual Price(INR)/Quintal":2329,"Percentage Difference":"2.62%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2278,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"-4.50%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2030,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"0.98%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1905,"Actual Price(INR)/Quintal":1840,"Percentage Difference":"-3.53%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1871,"Actual Price(INR)/Quintal":1917,"Percentage Difference":"2.40%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1834,"Actual Price(INR)/Quintal":1910,"Percentage Difference":"3.98%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1912,"Actual Price(INR)/Quintal":1959,"Percentage Difference":"2.40%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1921,"Actual Price(INR)/Quintal":1889,"Percentage Difference":"-1.69%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1937,"Actual Price(INR)/Quintal":1919,"Percentage Difference":"-0.94%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1984,"Actual Price(INR)/Quintal":2106,"Percentage Difference":"5.79%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2087,"Actual Price(INR)/Quintal":2055,"Percentage Difference":"-1.56%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2091,"Actual Price(INR)/Quintal":2099,"Percentage Difference":"0.38%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2029,"Actual Price(INR)/Quintal":2134,"Percentage Difference":"4.92%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2061,"Actual Price(INR)/Quintal":2072,"Percentage Difference":"0.53%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2055,"Actual Price(INR)/Quintal":2101,"Percentage Difference":"2.19%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2118,"Actual Price(INR)/Quintal":2140,"Percentage Difference":"1.03%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1233,"Actual Price(INR)/Quintal":1200,"Percentage Difference":"-2.75%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1218,"Actual Price(INR)/Quintal":1202,"Percentage Difference":"-2.75%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1217,"Actual Price(INR)/Quintal":1235,"Percentage Difference":"-2.75%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1219,"Actual Price(INR)/Quintal":1245,"Percentage Difference":"-2.75%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1229,"Actual Price(INR)/Quintal":1192,"Percentage Difference":"-3.10%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1203,"Actual Price(INR)/Quintal":1205,"Percentage Difference":"0.17%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1237,"Actual Price(INR)/Quintal":1299,"Percentage Difference":"4.77%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1282,"Actual Price(INR)/Quintal":1345,"Percentage Difference":"4.68%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1361,"Actual Price(INR)/Quintal":1381,"Percentage Difference":"1.45%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1374,"Actual Price(INR)/Quintal":1378,"Percentage Difference":"0.29%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1369,"Actual Price(INR)/Quintal":1404,"Percentage Difference":"2.49%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1371,"Actual Price(INR)/Quintal":1348,"Percentage Difference":"-1.71%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1357,"Actual Price(INR)/Quintal":1390,"Percentage Difference":"2.37%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1350,"Actual Price(INR)/Quintal":1420,"Percentage Difference":"4.93%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1458,"Actual Price(INR)/Quintal":1464,"Percentage Difference":"0.41%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1451,"Actual Price(INR)/Quintal":1420,"Percentage Difference":"-2.18%","YEAR":2021},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1451,"Actual Price(INR)/Quintal":1426,"Percentage Difference":"-1.75%","YEAR":2021},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1431,"Actual Price(INR)/Quintal":1416,"Percentage Difference":"-1.06%","YEAR":2021},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1407,"Actual Price(INR)/Quintal":1452,"Percentage Difference":"3.10%","YEAR":2021},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1446,"Actual Price(INR)/Quintal":1511,"Percentage Difference":"4.30%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1438,"Actual Price(INR)/Quintal":1506,"Percentage Difference":"4.52%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1484,"Actual Price(INR)/Quintal":1483,"Percentage Difference":"-0.07%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1475,"Actual Price(INR)/Quintal":1454,"Percentage Difference":"-1.44%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1447,"Actual Price(INR)/Quintal":1516,"Percentage Difference":"4.55%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1526,"Actual Price(INR)/Quintal":1597,"Percentage Difference":"4.45%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1535,"Actual Price(INR)/Quintal":1557,"Percentage Difference":"1.41%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1547,"Actual Price(INR)/Quintal":1616,"Percentage Difference":"4.27%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1546,"Actual Price(INR)/Quintal":1550,"Percentage Difference":"0.26%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1566,"Actual Price(INR)/Quintal":1587,"Percentage Difference":"1.32%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1549,"Actual Price(INR)/Quintal":1605,"Percentage Difference":"3.49%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1606,"Actual Price(INR)/Quintal":1618,"Percentage Difference":"0.74%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1609,"Actual Price(INR)/Quintal":1616,"Percentage Difference":"0.43%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1608,"Actual Price(INR)/Quintal":1625,"Percentage Difference":"1.05%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1581,"Actual Price(INR)/Quintal":1587,"Percentage Difference":"0.38%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1583,"Actual Price(INR)/Quintal":1604,"Percentage Difference":"1.31%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1569,"Actual Price(INR)/Quintal":1514,"Percentage Difference":"-3.63%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1551,"Actual Price(INR)/Quintal":1602,"Percentage Difference":"3.18%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1527,"Actual Price(INR)/Quintal":1528,"Percentage Difference":"0.07%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1524,"Actual Price(INR)/Quintal":1510,"Percentage Difference":"-0.93%","YEAR":2021},	
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1541,"Actual Price(INR)/Quintal":1585,"Percentage Difference":"2.78%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1550,"Actual Price(INR)/Quintal":1589,"Percentage Difference":"2.45%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1566,"Actual Price(INR)/Quintal":1608,"Percentage Difference":"2.61%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1570,"Actual Price(INR)/Quintal":1620,"Percentage Difference":"3.09%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1586,"Actual Price(INR)/Quintal":1648,"Percentage Difference":"3.76%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1689,"Actual Price(INR)/Quintal":1760,"Percentage Difference":"4.03%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1694,"Actual Price(INR)/Quintal":1618,"Percentage Difference":"-4.70%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1608,"Actual Price(INR)/Quintal":1628,"Percentage Difference":"1.23%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1662,"Actual Price(INR)/Quintal":1622,"Percentage Difference":"-2.47%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1898,"Actual Price(INR)/Quintal":2005,"Percentage Difference":"5.34%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1896,"Actual Price(INR)/Quintal":1891,"Percentage Difference":"-0.26%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1897,"Actual Price(INR)/Quintal":1880,"Percentage Difference":"-0.90%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1882,"Actual Price(INR)/Quintal":1916,"Percentage Difference":"1.77%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1978,"Actual Price(INR)/Quintal":1893,"Percentage Difference":"-4.49%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1935,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"-7.02%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1809,"Actual Price(INR)/Quintal":1750,"Percentage Difference":"-3.37%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1725,"Actual Price(INR)/Quintal":1792,"Percentage Difference":"3.74%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1738,"Actual Price(INR)/Quintal":1770,"Percentage Difference":"1.81%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1735,"Actual Price(INR)/Quintal":1768,"Percentage Difference":"1.87%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1769,"Actual Price(INR)/Quintal":1708,"Percentage Difference":"-3.57%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1749,"Actual Price(INR)/Quintal":1920,"Percentage Difference":"8.91%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"-3.19%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1803,"Actual Price(INR)/Quintal":1903,"Percentage Difference":"5.25%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1921,"Actual Price(INR)/Quintal":2013,"Percentage Difference":"4.57%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":2007,"Actual Price(INR)/Quintal":1915,"Percentage Difference":"-4.80%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1851,"Actual Price(INR)/Quintal":1861,"Percentage Difference":"0.54%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1860,"Actual Price(INR)/Quintal":1849,"Percentage Difference":"-0.59%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1850,"Actual Price(INR)/Quintal":1777,"Percentage Difference":"-4.11%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1775,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"1.39%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":1792,"Actual Price(INR)/Quintal":1775,"Percentage Difference":"-0.96%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":1705,"Actual Price(INR)/Quintal":1686,"Percentage Difference":"-1.13%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":1696,"Actual Price(INR)/Quintal":1698,"Percentage Difference":"0.12%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1610,"Actual Price(INR)/Quintal":1541,"Percentage Difference":"-4.48%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":1506,"Actual Price(INR)/Quintal":1524,"Percentage Difference":"1.18%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":1507,"Actual Price(INR)/Quintal":1380,"Percentage Difference":"-9.20%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":1304,"Actual Price(INR)/Quintal":1305,"Percentage Difference":"0.08%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":1302,"Actual Price(INR)/Quintal":1304,"Percentage Difference":"0.15%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":1269,"Actual Price(INR)/Quintal":1257,"Percentage Difference":"-0.95%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":1266,"Actual Price(INR)/Quintal":1241,"Percentage Difference":"-2.01%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":1232,"Actual Price(INR)/Quintal":1255,"Percentage Difference":"1.83%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":1231,"Actual Price(INR)/Quintal":1201,"Percentage Difference":"-2.50%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1137,"Actual Price(INR)/Quintal":1175,"Percentage Difference":"3.23%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1166,"Actual Price(INR)/Quintal":1185,"Percentage Difference":"1.60%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1193,"Actual Price(INR)/Quintal":1211,"Percentage Difference":"1.49%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":1201,"Actual Price(INR)/Quintal":1214,"Percentage Difference":"1.07%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":1210,"Actual Price(INR)/Quintal":1205,"Percentage Difference":"-0.41%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":1209,"Actual Price(INR)/Quintal":1191,"Percentage Difference":"-1.51%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":1206,"Actual Price(INR)/Quintal":1181,"Percentage Difference":"-2.12%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":1184,"Actual Price(INR)/Quintal":1186,"Percentage Difference":"0.17%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":1199,"Actual Price(INR)/Quintal":1204,"Percentage Difference":"0.42%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":1203,"Actual Price(INR)/Quintal":1232,"Percentage Difference":"2.35%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":1223,"Actual Price(INR)/Quintal":1247,"Percentage Difference":"1.92%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":1231,"Actual Price(INR)/Quintal":1280,"Percentage Difference":"3.83%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":1235,"Actual Price(INR)/Quintal":1294,"Percentage Difference":"4.56%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":1241,"Actual Price(INR)/Quintal":1284,"Percentage Difference":"3.35%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":1243,"Actual Price(INR)/Quintal":1246,"Percentage Difference":"0.24%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":1250,"Actual Price(INR)/Quintal":1268,"Percentage Difference":"1.42%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1758,
    "Actual Price(INR)/Quintal": 1779,
    "Percentage Difference": "1.18%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1758,
    "Actual Price(INR)/Quintal": 1835,
    "Percentage Difference": "4.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1819,
    "Actual Price(INR)/Quintal": 1839,
    "Percentage Difference": "1.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1826,
    "Actual Price(INR)/Quintal": 1862,
    "Percentage Difference": "1.93%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1831,
    "Actual Price(INR)/Quintal": 1922,
    "Percentage Difference": "4.73%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1930,
    "Actual Price(INR)/Quintal": 1942,
    "Percentage Difference": "0.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1941,
    "Actual Price(INR)/Quintal": 1913,
    "Percentage Difference": "-1.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1929,
    "Actual Price(INR)/Quintal": 1954,
    "Percentage Difference": "1.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1920,
    "Actual Price(INR)/Quintal": 1999,
    "Percentage Difference": "3.95%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1932,
    "Actual Price(INR)/Quintal": 1992,
    "Percentage Difference": "3.01%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1941,
    "Actual Price(INR)/Quintal": 2007,
    "Percentage Difference": "3.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1990,
    "Actual Price(INR)/Quintal": 2043,
    "Percentage Difference": "2.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 2003,
    "Actual Price(INR)/Quintal": 2113,
    "Percentage Difference": "5.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 2102,
    "Actual Price(INR)/Quintal": 2187,
    "Percentage Difference": "3.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 2134,
    "Actual Price(INR)/Quintal": 2175,
    "Percentage Difference": "1.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 2131,
    "Actual Price(INR)/Quintal": 2238,
    "Percentage Difference": "4.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 2175,
    "Actual Price(INR)/Quintal": 2239,
    "Percentage Difference": "2.86%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 2249,
    "Actual Price(INR)/Quintal": 2198,
    "Percentage Difference": "-2.32%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 2206,
    "Actual Price(INR)/Quintal": 2241,
    "Percentage Difference": "1.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 2201,
    "Actual Price(INR)/Quintal": 2305,
    "Percentage Difference": "4.51%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 2244,
    "Actual Price(INR)/Quintal": 2334,
    "Percentage Difference": "3.86%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2297,
    "Actual Price(INR)/Quintal": 2299,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 2299,
    "Actual Price(INR)/Quintal": 2251,
    "Percentage Difference": "-2.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 2246,
    "Actual Price(INR)/Quintal": 2233,
    "Percentage Difference": "-0.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 2199,
    "Actual Price(INR)/Quintal": 2201,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2200,
    "Actual Price(INR)/Quintal": 2246,
    "Percentage Difference": "2.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2298,
    "Actual Price(INR)/Quintal": 2306,
    "Percentage Difference": "0.35%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2290,
    "Actual Price(INR)/Quintal": 2303,
    "Percentage Difference": "0.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2297,
    "Actual Price(INR)/Quintal": 2331,
    "Percentage Difference": "1.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 2301,
    "Actual Price(INR)/Quintal": 2330,
    "Percentage Difference": "1.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2300,
    "Actual Price(INR)/Quintal": 2305,
    "Percentage Difference": "0.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2303,
    "Actual Price(INR)/Quintal": 2305,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2277,
    "Actual Price(INR)/Quintal": 2280,
    "Percentage Difference": "0.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2296,
    "Actual Price(INR)/Quintal": 2325,
    "Percentage Difference": "1.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2263,
    "Actual Price(INR)/Quintal": 2269,
    "Percentage Difference": "0.26%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2268,
    "Actual Price(INR)/Quintal": 2196,
    "Percentage Difference": "-3.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2099,
    "Actual Price(INR)/Quintal": 2087,
    "Percentage Difference": "-0.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2004,
    "Actual Price(INR)/Quintal": 1897,
    "Percentage Difference": "-5.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2002,
    "Actual Price(INR)/Quintal": 1886,
    "Percentage Difference": "-6.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1990,
    "Actual Price(INR)/Quintal": 1938,
    "Percentage Difference": "-2.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1878,
    "Actual Price(INR)/Quintal": 1800,
    "Percentage Difference": "-4.33%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1882,
    "Actual Price(INR)/Quintal": 1875,
    "Percentage Difference": "-0.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1923,
    "Actual Price(INR)/Quintal": 1986,
    "Percentage Difference": "3.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1818,
    "Actual Price(INR)/Quintal": 1800,
    "Percentage Difference": "-1.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1832,
    "Actual Price(INR)/Quintal": 1900,
    "Percentage Difference": "3.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1835,
    "Actual Price(INR)/Quintal": 1813,
    "Percentage Difference": "-1.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1826,
    "Actual Price(INR)/Quintal": 1803,
    "Percentage Difference": "-1.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1996,
    "Actual Price(INR)/Quintal": 1981,
    "Percentage Difference": "-0.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1199,
    "Actual Price(INR)/Quintal": 1212,
    "Percentage Difference": "1.07%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1171,
    "Actual Price(INR)/Quintal": 1190,
    "Percentage Difference": "1.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1210,
    "Actual Price(INR)/Quintal": 1201,
    "Percentage Difference": "-0.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1207,
    "Actual Price(INR)/Quintal": 1206,
    "Percentage Difference": "-0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1211,
    "Actual Price(INR)/Quintal": 1232,
    "Percentage Difference": "1.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1222,
    "Actual Price(INR)/Quintal": 1249,
    "Percentage Difference": "2.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1233,
    "Actual Price(INR)/Quintal": 1193,
    "Percentage Difference": "-3.35%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1203,
    "Actual Price(INR)/Quintal": 1197,
    "Percentage Difference": "-0.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1211,
    "Actual Price(INR)/Quintal": 1216,
    "Percentage Difference": "0.41%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1201,
    "Actual Price(INR)/Quintal": 1176,
    "Percentage Difference": "-2.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1160,
    "Actual Price(INR)/Quintal": 1110,
    "Percentage Difference": "-4.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1121,
    "Actual Price(INR)/Quintal": 1126,
    "Percentage Difference": "0.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1189,
    "Actual Price(INR)/Quintal": 1208,
    "Percentage Difference": "1.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1179,
    "Actual Price(INR)/Quintal": 1200,
    "Percentage Difference": "1.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1184,
    "Actual Price(INR)/Quintal": 1240,
    "Percentage Difference": "4.52%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1283,
    "Actual Price(INR)/Quintal": 1223,
    "Percentage Difference": "-4.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1192,
    "Percentage Difference": "-0.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1203,
    "Actual Price(INR)/Quintal": 1222,
    "Percentage Difference": "1.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1192,
    "Actual Price(INR)/Quintal": 1212,
    "Percentage Difference": "1.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1210,
    "Actual Price(INR)/Quintal": 1211,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1193,
    "Actual Price(INR)/Quintal": 1216,
    "Percentage Difference": "1.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1195,
    "Actual Price(INR)/Quintal": 1208,
    "Percentage Difference": "1.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1190,
    "Actual Price(INR)/Quintal": 1218,
    "Percentage Difference": "2.30%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1223,
    "Actual Price(INR)/Quintal": 1247,
    "Percentage Difference": "1.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1229,
    "Actual Price(INR)/Quintal": 1251,
    "Percentage Difference": "1.76%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1259,
    "Actual Price(INR)/Quintal": 1300,
    "Percentage Difference": "3.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1255,
    "Actual Price(INR)/Quintal": 1275,
    "Percentage Difference": "1.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1253,
    "Actual Price(INR)/Quintal": 1294,
    "Percentage Difference": "3.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1294,
    "Actual Price(INR)/Quintal": 1277,
    "Percentage Difference": "-1.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1292,
    "Actual Price(INR)/Quintal": 1293,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1295,
    "Actual Price(INR)/Quintal": 1366,
    "Percentage Difference": "5.20%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1346,
    "Actual Price(INR)/Quintal": 1373,
    "Percentage Difference": "1.97%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1352,
    "Actual Price(INR)/Quintal": 1421,
    "Percentage Difference": "4.86%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1412,
    "Actual Price(INR)/Quintal": 1356,
    "Percentage Difference": "-4.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1334,
    "Actual Price(INR)/Quintal": 1361,
    "Percentage Difference": "1.98%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1354,
    "Actual Price(INR)/Quintal": 1373,
    "Percentage Difference": "1.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 1373,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "2.28%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1395,
    "Actual Price(INR)/Quintal": 1468,
    "Percentage Difference": "4.97%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 1369,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "2.56%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1370,
    "Actual Price(INR)/Quintal": 1470,
    "Percentage Difference": "6.80%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1475,
    "Actual Price(INR)/Quintal": 1668,
    "Percentage Difference": "11.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1683,
    "Actual Price(INR)/Quintal": 1688,
    "Percentage Difference": "0.30%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1703,
    "Actual Price(INR)/Quintal": 1710,
    "Percentage Difference": "0.41%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1710,
    "Actual Price(INR)/Quintal": 1767,
    "Percentage Difference": "3.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1742,
    "Actual Price(INR)/Quintal": 1790,
    "Percentage Difference": "2.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1766,
    "Actual Price(INR)/Quintal": 1799,
    "Percentage Difference": "1.83%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1755,
    "Actual Price(INR)/Quintal": 1712,
    "Percentage Difference": "-2.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1701,
    "Actual Price(INR)/Quintal": 1760,
    "Percentage Difference": "3.35%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1524,
    "Actual Price(INR)/Quintal": 1493,
    "Percentage Difference": "-2.08%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1555,
    "Actual Price(INR)/Quintal": 1489,
    "Percentage Difference": "-4.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1558,
    "Actual Price(INR)/Quintal": 1525,
    "Percentage Difference": "-2.16%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1543,
    "Actual Price(INR)/Quintal": 1519,
    "Percentage Difference": "-1.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1528,
    "Actual Price(INR)/Quintal": 1541,
    "Percentage Difference": "0.84%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1549,
    "Actual Price(INR)/Quintal": 1618,
    "Percentage Difference": "4.26%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1595,
    "Actual Price(INR)/Quintal": 1621,
    "Percentage Difference": "1.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1591,
    "Actual Price(INR)/Quintal": 1646,
    "Percentage Difference": "3.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1609,
    "Actual Price(INR)/Quintal": 1601,
    "Percentage Difference": "-0.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1567,
    "Actual Price(INR)/Quintal": 1616,
    "Percentage Difference": "3.03%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1562,
    "Actual Price(INR)/Quintal": 1592,
    "Percentage Difference": "1.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1554,
    "Actual Price(INR)/Quintal": 1605,
    "Percentage Difference": "3.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1595,
    "Actual Price(INR)/Quintal": 1622,
    "Percentage Difference": "1.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1599,
    "Actual Price(INR)/Quintal": 1634,
    "Percentage Difference": "2.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1616,
    "Actual Price(INR)/Quintal": 1653,
    "Percentage Difference": "2.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1624,
    "Actual Price(INR)/Quintal": 1662,
    "Percentage Difference": "2.29%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1626,
    "Actual Price(INR)/Quintal": 1640,
    "Percentage Difference": "0.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1629,
    "Actual Price(INR)/Quintal": 1643,
    "Percentage Difference": "0.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1628,
    "Actual Price(INR)/Quintal": 1630,
    "Percentage Difference": "0.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1625,
    "Actual Price(INR)/Quintal": 1587,
    "Percentage Difference": "-2.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1563,
    "Actual Price(INR)/Quintal": 1595,
    "Percentage Difference": "2.01%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1564,
    "Actual Price(INR)/Quintal": 1595,
    "Percentage Difference": "1.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1561,
    "Actual Price(INR)/Quintal": 1580,
    "Percentage Difference": "1.20%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1562,
    "Actual Price(INR)/Quintal": 1570,
    "Percentage Difference": "0.51%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1573,
    "Actual Price(INR)/Quintal": 1600,
    "Percentage Difference": "1.69%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1568,
    "Actual Price(INR)/Quintal": 1603,
    "Percentage Difference": "2.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1613,
    "Actual Price(INR)/Quintal": 1637,
    "Percentage Difference": "1.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1618,
    "Actual Price(INR)/Quintal": 1680,
    "Percentage Difference": "3.69%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1648,
    "Actual Price(INR)/Quintal": 1630,
    "Percentage Difference": "-1.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1608,
    "Actual Price(INR)/Quintal": 1552,
    "Percentage Difference": "-3.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1518,
    "Actual Price(INR)/Quintal": 1554,
    "Percentage Difference": "2.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1545,
    "Actual Price(INR)/Quintal": 1603,
    "Percentage Difference": "3.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1578,
    "Actual Price(INR)/Quintal": 1614,
    "Percentage Difference": "2.23%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1577,
    "Actual Price(INR)/Quintal": 1497,
    "Percentage Difference": "-5.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1502,
    "Actual Price(INR)/Quintal": 1433,
    "Percentage Difference": "-4.82%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1420,
    "Actual Price(INR)/Quintal": 1400,
    "Percentage Difference": "-1.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1388,
    "Actual Price(INR)/Quintal": 1323,
    "Percentage Difference": "-4.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1290,
    "Actual Price(INR)/Quintal": 1240,
    "Percentage Difference": "-4.03%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1263,
    "Percentage Difference": "4.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1265,
    "Actual Price(INR)/Quintal": 1199,
    "Percentage Difference": "-5.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 1161,
    "Actual Price(INR)/Quintal": 1177,
    "Percentage Difference": "1.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 1213,
    "Actual Price(INR)/Quintal": 1162,
    "Percentage Difference": "-4.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 1177,
    "Actual Price(INR)/Quintal": 1191,
    "Percentage Difference": "1.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 1173,
    "Actual Price(INR)/Quintal": 1203,
    "Percentage Difference": "2.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1205,
    "Actual Price(INR)/Quintal": 1234,
    "Percentage Difference": "2.35%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1238,
    "Actual Price(INR)/Quintal": 1244,
    "Percentage Difference": "0.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1236,
    "Actual Price(INR)/Quintal": 1199,
    "Percentage Difference": "-3.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1191,
    "Actual Price(INR)/Quintal": 1210,
    "Percentage Difference": "1.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1401,
    "Actual Price(INR)/Quintal": 1462,
    "Percentage Difference": "4.17%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1494,
    "Actual Price(INR)/Quintal": 1483,
    "Percentage Difference": "-0.74%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1493,
    "Actual Price(INR)/Quintal": 1528,
    "Percentage Difference": "2.29%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1494,
    "Actual Price(INR)/Quintal": 1455,
    "Percentage Difference": "-2.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1487,
    "Actual Price(INR)/Quintal": 1489,
    "Percentage Difference": "0.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1413,
    "Actual Price(INR)/Quintal": 1551,
    "Percentage Difference": "8.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1594,
    "Actual Price(INR)/Quintal": 1539,
    "Percentage Difference": "-3.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1592,
    "Actual Price(INR)/Quintal": 1550,
    "Percentage Difference": "-2.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1508,
    "Actual Price(INR)/Quintal": 1722,
    "Percentage Difference": "12.75%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1671,
    "Actual Price(INR)/Quintal": 1657,
    "Percentage Difference": "-0.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1663,
    "Actual Price(INR)/Quintal": 1637,
    "Percentage Difference": "-1.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1646,
    "Actual Price(INR)/Quintal": 1679,
    "Percentage Difference": "1.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1641,
    "Actual Price(INR)/Quintal": 1674,
    "Percentage Difference": "1.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1668,
    "Actual Price(INR)/Quintal": 1653,
    "Percentage Difference": "-0.91%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1666,
    "Actual Price(INR)/Quintal": 1722,
    "Percentage Difference": "3.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1667,
    "Actual Price(INR)/Quintal": 1818,
    "Percentage Difference": "8.31%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1796,
    "Actual Price(INR)/Quintal": 1787,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1807,
    "Actual Price(INR)/Quintal": 1752,
    "Percentage Difference": "-3.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1731,
    "Actual Price(INR)/Quintal": 1720,
    "Percentage Difference": "-0.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1689,
    "Actual Price(INR)/Quintal": 1775,
    "Percentage Difference": "4.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1759,
    "Actual Price(INR)/Quintal": 1706,
    "Percentage Difference": "-3.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1691,
    "Actual Price(INR)/Quintal": 1712,
    "Percentage Difference": "1.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1724,
    "Actual Price(INR)/Quintal": 1662,
    "Percentage Difference": "-3.73%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1682,
    "Actual Price(INR)/Quintal": 1668,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1681,
    "Actual Price(INR)/Quintal": 1687,
    "Percentage Difference": "0.36%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1692,
    "Actual Price(INR)/Quintal": 1687,
    "Percentage Difference": "-0.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1706,
    "Actual Price(INR)/Quintal": 1706,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1715,
    "Actual Price(INR)/Quintal": 1748,
    "Percentage Difference": "1.89%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1721,
    "Actual Price(INR)/Quintal": 1703,
    "Percentage Difference": "-1.06%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1730,
    "Actual Price(INR)/Quintal": 1674,
    "Percentage Difference": "-3.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1713,
    "Actual Price(INR)/Quintal": 1591,
    "Percentage Difference": "-7.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1721,
    "Actual Price(INR)/Quintal": 1594,
    "Percentage Difference": "-7.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1607,
    "Actual Price(INR)/Quintal": 1588,
    "Percentage Difference": "-1.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1601,
    "Actual Price(INR)/Quintal": 1578,
    "Percentage Difference": "-1.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1552,
    "Actual Price(INR)/Quintal": 1513,
    "Percentage Difference": "-2.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1495,
    "Actual Price(INR)/Quintal": 1472,
    "Percentage Difference": "-1.56%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1461,
    "Actual Price(INR)/Quintal": 1473,
    "Percentage Difference": "0.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1483,
    "Actual Price(INR)/Quintal": 1381,
    "Percentage Difference": "-7.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1396,
    "Actual Price(INR)/Quintal": 1495,
    "Percentage Difference": "6.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1485,
    "Actual Price(INR)/Quintal": 1505,
    "Percentage Difference": "1.33%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 1493,
    "Actual Price(INR)/Quintal": 1484,
    "Percentage Difference": "-0.60%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 1488,
    "Actual Price(INR)/Quintal": 1516,
    "Percentage Difference": "1.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 1507,
    "Actual Price(INR)/Quintal": 1601,
    "Percentage Difference": "5.87%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 1599,
    "Actual Price(INR)/Quintal": 1576,
    "Percentage Difference": "-1.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1576,
    "Actual Price(INR)/Quintal": 1557,
    "Percentage Difference": "-1.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1574,
    "Actual Price(INR)/Quintal": 1521,
    "Percentage Difference": "-3.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1501,
    "Actual Price(INR)/Quintal": 1499,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1504,
    "Actual Price(INR)/Quintal": 1502,
    "Percentage Difference": "-0.13%",
    "YEAR": 2016
  }
];
